@import url("https://fonts.googleapis.com/css?family=Nunito:600,700&display=swap");
* {
  box-sizing: border-box;
}

.global-wrapper{

    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    overflow-y: scroll;
}



.main-container {
  text-align: center;
  color: #fff;
  margin-top: 25px;
}
.main-container > header {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 0 auto 15px auto;
  position: relative;
  height: 40px;
  width: 250px;
  overflow: hidden;
}

.header-headings {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  color:#0008;
}
.header-headings > span {
  margin: 3px 0;
}
.header-headings.sign-in {
  transform: translateY(0);
}
.header-headings.sign-up {
  transform: translateY(-35px);
}
.header-headings.forgot {
  transform: translateY(-70px);
}

.options {
  display: flex;
  align-items: center;
  width: 350px;
  color:#0008;
}
@media screen and (max-width: 380px) {
  .options {
    width: 100%;
  }
}
.options > li {
  cursor: pointer;
  opacity: 0.5;
  transition: all 0.2s ease;
}
.options > li:hover {
  opacity: 1;
}
.options > li.active {
  opacity: 1;
}
.options > li:nth-of-type(2) {
  margin-left: 15px;
}
.options > li:last-of-type {
  margin-left: auto;
}

.account-form {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.account-form-fields {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.account-form-fields.sign-in {
  max-height: 125px;
}
.account-form-fields.sign-up {
  max-height: 550px;
}
.account-form-fields.forgot {
  max-height: 63px;
}
.account-form-fields > input,
.account-form-fields > div > input {
  border: 0;
  margin-bottom: 10px;
  padding: 15px;
  font-size: 1rem;
  font-family: Nunito, sans-serif;
  color: #000;
  border-radius: 15px;
  background-color: rgb(250,250,250);
}
.account-form-fields > input::-moz-placeholder ,
.account-form-fields > div > input::-moz-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder ,
.account-form-fields >  div > input:-ms-input-placeholder  {
  color: #aaa;
}
.account-form-fields > input::placeholder ,
.account-form-fields > div > input::placeholder{
  color: #aaa;
}
.account-form-fields > input::-webkit-input-placeholder,
.account-form-fields > div > input::-webkit-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder ,
.account-form-fields  > div > input:-ms-input-placeholder {
  color: #aaa;
}
.account-form-fields > input:focus,
.account-form-fields > div > input:focus {
  outline: none;
}

.btn-submit-form {
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 15px 0;
  border-radius: 15px;
  color: #fff;
  font-size: 1rem;
  font-family: Nunito, sans-serif;

  background: var(--primary-color);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);
  transition: all 0.2s ease;
}
.btn-submit-form:hover {
  background: var(--secondary-color);
}
.btn-submit-form:active, .btn-submit-form:focus {
  outline: none;
  background: var(--primary-color);
}

footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 30px 0;
  text-align: center;
}
footer > a {
  color: #fff;
  font-weight: 700;
}

.login_footer{
position: absolute; 
bottom: 30px; 
right: 100px;
}

.login_footer_troilo{
  bottom: 30px; 
  right: 100px;
  text-align: center;
  }

@media screen and (max-width: 992px) {
  .login_footer{
    bottom: 30px;
    text-align: center;
    } 
}
