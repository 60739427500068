/*SideBar Meny CSS Start*/
.sidebarMenuBlock {
  position: fixed;
  z-index: 1;
  overflow-y: auto;
  left: 70px;
  width: calc(300px - 10px);
  height: 100vh;
  background-color: #fff;
  box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 23px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  transition-duration: 500ms;
  visibility: visible;
  transition-property: transform;
  top: 0;
}
.sidebarMenuBlock.filterleft {
  left: 0;
}

.input-group {
  position: relative;
}
.input-group svg {
  position: absolute;
  right: 15px;
  top: 8px;
  z-index: 9;
}
.input-group svg path {
  fill: #365da1;
}
.sidebarMenuBlock .btn {
  width: 100%;
}
.btn-reset {
  border: 1px solid #df0000 !important;
  color: #df0000 !important;
  padding: 0 !important;
  height: 34px;
  font-weight: 500 !important;
  opacity: 9 !important;
}

.productCategory-content {
  width: calc(100% - 330px) !important;
  margin-left: auto !important;
  margin-right: 30px !important;
}
.product-banner-block {
  height: 257px;
  margin-top: 20px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  object-fit: cover;
}
.product-item-block-wrap {
  /* padding-bottom: 60px; */
  padding-bottom: 21px;
}
.product-item-block {
  border-radius: 8px;
  background: #fff;
  box-shadow: 4px 4px 25px 0px rgba(0, 0, 0, 0.1);
  padding: 15px;
  height: 100%;
}
.product-item-block .container-slide img {
  /* height: 195px; */
  width: 100%;
  height: auto;
  object-fit: contain;
}
.product-price-block ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 5px;
}
.product-price-block ul li {
  list-style: none;
  height: 45px;
  width: 45px;
  border: 1px solid #0fa2ff;
  border-radius: 4px;
}
.product-price-block ul li:nth-child(2) {
  border: 1px solid #55b941;
}
.product-price-block ul li:nth-child(3) {
  border: 1px solid #365da1;
}
.product-price-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 20px 0; */
  padding-top: 10px;
}
.product-item-Name {
  min-height: 60px;
  margin-top: 10px;
}
.product-item-block .btn {
  background-color: #55b941;
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  height: 34px;
  padding: 0;
}
.product-item-block .btn:hover {
  background-color: #55b941;
  color: #fff;
}
.product-item-block .slick-dots {
  display: none !important;
}

.product-item-block .discounted-price {
  font-size: 14px !important;
}
.product-item-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 17px;
  color: #0d0040;
  background: #fff;
  padding: 10px 11px 11px 11px;
  border-radius: 8px;
  line-height: normal;
  margin-bottom: 5px;
}
.product-item-list.active {
  background: #f5f7fa;
  font-weight: 800 !important;
  color: #365da1;
}
.product-category-block .pointer.active {
  color: #365da1 !important;
  font-weight: 600 !important;
}
.product-item-list .font-icon-category {
  margin-right: 8px;
}
.priceBlock {
  text-align: right;
  line-height: normal;
}
.font-icon svg {
  transform: rotate(180deg);
  font-size: 10px;
}

.product-category-block {
  direction: rtl;
  overflow-y: auto;
}
.product-category-block > div {
  direction: ltr;
}
.product-category-block::-webkit-scrollbar {
  width: 2px;
}
.product-category-block::-webkit-scrollbar-track {
  background: #e5e5e5;
}
.product-category-block::-webkit-scrollbar-thumb {
  background: #365da1;
}
.product-category-block::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.product-price-block .priceBlock .price_span {
  font-size: 29px;
  font-weight: 700;
}

.sidebarMenuBlockMobile {
  display: none;
}

.sidebar-mobile {
  background-color: #fff;
  width: 100% !important;
  overflow: unset !important;
  top: 180px;
}

/*category Product Section CSS start*/
.categories-static-inner {
  margin-bottom: 35px;
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.categories-static-inner .product-item-block-wrap {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.categories-static .mb-5 {
  margin-bottom: 45px !important;
}
.categories-static .categories-static-header {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  /* padding: 16px; */
  padding: 16px 32px 14px 23px;
  background: #fff;
  box-shadow: 6px 6px 27px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 32px;
  align-items: center;
}
.categories-static .categories-static-header button {
  border-radius: 4px;
  background: transparent;
  padding: 7px 15px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #df0000;
  color: #df0000;
  line-height: 16px;
  height: 33px;
  display: flex;
}
.categories-static .categories-static-header ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 16px;
}
.categories-static .categories-static-header ul li {
  list-style: none;
  height: 45px;
  width: 45px;
  background-color: #8fb63b;
  border-radius: 4px;
}
.categories-static .categories-static-header ul li:nth-child(2) {
  background-color: #2b8ac4;
}
.categories-static .categories-static-header ul li:nth-child(3) {
  background-color: #bf353d;
}
.categories-static .categories-static-header ul li:nth-child(4) {
  background-color: #ff87c0;
}
.categories-static h2 {
  color: #0d0040;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 5px;
  margin-bottom: 5px;
  align-items: flex-end;
}
.categories-static h2 button {
  border-radius: 4px;
  background: var(--primary-color);
  padding: 8px 24px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border: none;
}
.product-item-Name p {
  color: #0d0040;
}

/*SideBar Meny CSS End*/

@media screen and (max-width: 1600px) {
  .product-banner-block {
    height: auto;
  }
  .product-price-block ul li {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 575px) {
  .productCategory-content {
    /* width: calc(100% - 0px) !important; */
  }
  .product-banner-block {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  /*.productCategory-content {
    width: calc(100% - 0px) !important;
  }
  .product-banner-block {
    height: auto;
    margin: 100px 0 30px 0;
  }
   .sidebarMenuBlockMobile {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
    z-index: 9;
    position: relative;
    margin-top: -15px;
    display: block;
    position: fixed;
    width: 100%;
    top: 135px;
  }
  .sidebarMenuBlockMobile .search-field {
    position: relative;
    width: 100%;
  }
  .sidebarMenuBlockMobile .search-field svg {
    position: absolute;
    right: 12px;
    top: 10px;
  }
  .sidebarMenuBlockMobile-item {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .sidebarMenuBlockMobile-item .btn-filter,
  .sidebarMenuBlockMobile-item .form-control {
    border: 1px solid #365da1;
    border-radius: 4px;
    box-shadow: none;
    background-color: #fff;
    height: 46px;
  }
  .sidebarMenuBlockMobile-item .form-control,
  .sidebarMenuBlockMobile-item .btn-filter {
    padding: 10px;
  } */

  .product-item-block .container-slide img {
    /* height: 90px; */
  }

  .product-item-Name p {
    font-size: 12px !important;
    text-overflow: initial !important;
    white-space: inherit !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: normal !important;
    height: 32px;
  }
  .product-item-Name p:first-child {
    font-size: 14px !important;
    height: 17px;
  }
  .product-price-block ul li {
    height: 25px;
    width: 25px;
  }

  .product-price-block ul li:nth-child(3) {
    display: none;
  }
  .product-item-block .discounted-price,
  .product-price-block .priceBlock .price-option {
    font-size: 11px !important;
  }
  .product-item-block .price_span span {
    font-size: 18px !important;
  }
  .product-category-block {
    /* height: calc(100% - (210px + 108px)) !important; */
    /* height: calc(100% - (210px + 138px)) !important; */
    height: calc(100% - 180px) !important;
  }

  .main-modal .product-price-block ul li {
    height: 30px;
    width: 30px;
  }
  .main-modal .slick-slide img {
    /* height: 150px !important; */
  }

  .product-price-block .priceBlock .price_span {
    font-size: 24px !important;
  }
}

/*Modal CSS Design Start*/
.slider-sidebar-block {
  overflow-y: auto;
  height: auto;
  overflow-x: hidden;
}
.modal .modal-body {
  height: 100%;
  padding: 0px 0 20px 0;
}

.slider-sidebar .sidebar-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px 15px 0px 15px;
  margin-bottom: 10px;
  margin-right: 50px;
}
.slider-sidebar .sidebar-card img {
  border-radius: 8px;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
}

.slider-sidebar .sidebar-card p {
  margin-bottom: 0;
  font-size: 12px;
}
.slider-sidebar h5 {
  font-size: 16px;
  font-weight: 500;
  color: #0d0040;
  margin-bottom: 18px;
}
.slider-sidebar .sidebar-card h5 {
  font-size: 20px;
  font-weight: 800;
  color: #0d0040;
}
.slider-sidebar .sidebar-card h6 {
  font-size: 16px;
  font-weight: 400;
  color: #0d0040;
  margin-bottom: 0;
}

.main-modal .product-price-block {
  flex-direction: row-reverse;
  gap: 70px;
}
.main-modal .product-price-block .priceBlock {
  text-align: left;
}
.main-modal .product-price-block .discounted-price {
  text-align: left;
  font-size: 20px;
}
.main-modal .product-price-block .price_span {
  font-size: 60px;
  font-weight: 700;
  line-height: 56px;
}
.main-modal .product-price-block .price_span .currency {
  font-size: 28px;
}
.main-modal .product-price-block .price-option {
  font-size: 20px;
  font-style: italic;
}

.main-modal .product-price-block ul li {
  border: 1px solid #ff720b;
}
.main-modal .product-price-block ul li:nth-child(2) {
  border: 1px solid #365da1;
}
.main-modal .product-price-block ul li:nth-child(3) {
  border: 1px solid #ca3737;
}
/* .modal .modal-dialog.custom-bs-modal .modal-footer {
    top: auto !important;
    bottom: 0;
}  */
.modal-footer .product-modal-footer-btn {
  justify-content: center;
}

.modal {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
}

.modal-backdrop.fade {
  opacity: 0 !important;
}
.modal.fade .modal-dialog.custom-bs-modal {
  transform: translate(0, 0px) !important;
}
.modal .main-modal .modal-content {
  position: relative;
  background-color: #fefefe;
  /* padding: 20px; */
  border: 1px solid #888;
  animation-name: slidein;
  animation-duration: 0.5s;
  border-radius: 0 !important;
}

@keyframes slidein {
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
}

.custom-popover {
  z-index: 9;
}

.custom-popover.fade {
  transition: none !important;
}

.main-modal .modal-footer {
  justify-content: start !important;
  border: 0 !important;
  background: #f5f7fa !important;
  border-radius: 8px !important;
  top: 0 !important;
  left: 20px;
  right: 20px;
  height: 32px !important;
}
.main-modal .modal-footer button {
  padding-left: 30px !important;
  padding-right: 30px !important;
  height: 32px !important;
  box-shadow: none !important;
  border: 0;
  position: relative;
  min-width: 115px;
}
.main-modal .modal-footer button:after {
  content: "";
  position: absolute;
  right: 0;
  border-right: 1px solid #c9c9c9;
  width: 1px;
  height: 20px;
}
.main-modal .modal-footer button:last-child {
  border-right: 0 !important;
}
.main-modal .modal-footer button:last-child:after {
  display: none;
}

.custom-share-container {
  justify-content: center;
}
.delete-modalBlock .modal-body {
  text-align: center;
  padding: 15px;
}
.delete-modalBlock .modal-footer {
  justify-content: space-between;
}

/*Tag Block Styling*/

.tag-block {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.tag-block .tag-block-item {
  display: flex;
  flex-direction: column;
}
.tag-block .tag-block-item img {
  /* width: 40px; */
  width: 45px;
  height: 45px;
}
.tag-block .tag-block-item span {
  font-size: 14px;
}
.tag-price-modal-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 13px;
  margin-bottom: 13px;
  margin-top: 30px !important;
}

.product-price-modal {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-bottom: 24px;
}

/*Grocery Product Modal CSS Start*/
.categories-static.mobile-view .categories-static-header {
  display: none;
}
.product-block-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product-block-inner .font-icon {
  /* position: absolute; */
  right: 10px;
  top: 7px;
  width: 20px;
  text-align: center;
  cursor: pointer;
  z-index: 9;
}
.product-block-inner .rotate.font-icon svg {
  transform: rotate(-90deg);
}
.priceBox {
  position: relative;
  padding: 8px 0;
}
.priceBox .offerPrice {
  /* position: absolute; */
  top: 0;
  left: 0;
  font-size: 20px;
  height: 20px;
  line-height: 20px;
  font-style: italic;
  font-weight: 400;
  color: #0d0040;
}
.priceBox .priceKg {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  font-weight: 500;
  font-style: italic;
  font-weight: 400;
  color: #0d0040;
}
.categoriesPrice {
  display: flex;
  flex-direction: column;
}
.categoriesPrice .priceinner {
  display: flex;
  flex-direction: column-reverse;
}
.categoriesPrice .price_span {
  line-height: normal;
}
.categoriesPrice .priceKg {
  font-size: 15px;
  line-height: 15px;
  font-style: italic;
  font-weight: 400;
  color: #0d0040;
  text-align: right;
}
.categoriesPrice .product-price-block {
  padding: 0;
  align-items: flex-end;
}
.categoriesPrice .product-price-block .price_span {
  font-size: 24px;
  font-weight: bold;
}
.categoriesPrice .offerPrice {
  font-size: 18px;
  height: 18px;
  line-height: 20px;
  font-style: italic;
  font-weight: 400;
  color: #0d0040;
  text-align: right;
  top: 0;
}
.categoriesPrice .product-price-block .price_span span {
  font-size: 24px !important;
  font-weight: 400 !important;
}
@media screen and (max-width: 1440px) {
  .product-item-block .queProductBox > button {
    padding: 4px 5px !important;
  }
}
@media screen and (max-width: 1280px) {
  .main-modal .modal-footer button {
    flex-direction: row !important;
    gap: 10px;
  }
  .modal .modal-body {
    /* height: 100% !important;
    padding-top: 10px !important; */
  }
}
@media screen and (max-width: 1199px) {
  .filtered-products {
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 991px) {
  .product-item-block .queProductBox > button {
    width: 100%;
    height: auto;
    /* line-height: 14px; */
  }
  .sidebarMenuBlock {
    width: calc(300px - 90px);
    padding: 15px;
  }
  .productCategory-content {
    width: calc(100% - 245px) !important;
  }
  .filtered-products {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 768px) {
  .grocery-product-quantity h6 {
    font-size: 14px;
  }
  .main-modal .product-price-block .discounted-price {
    text-align: right;
  }
  .main-modal .product-price-block .price_span {
    line-height: 46px !important;
  }
  .main-modal .product-price-block ul {
    position: absolute;
    top: 40px;
    right: 30px;
  }
  /* .main-modal .slick-slider {
    margin: 70px 0 0 0;
  }
  .main-modal .modal-innerBlock {
    display: block !important;
  } */

  .main-modal .product-price-block {
    margin: 20px 0 0px 0;
  }
  /* .modal.fade .modal-content .modal-body .product-details-content {
    padding-right: 16px !important;
  } */
  .modal.fade .modal-content .add-cart-modal-btn {
    height: 32px !important;
  }
  /* .main-modal .select-variety {
    display: none;
  } */
  .slider-sidebar,
  .modal .has-related-products .slider-sidebar {
    display: none;
  }

  .custom-popover
    .custom-popover-header
    .header-left-content
    .forward-icon
    .shareButton {
    height: 30px;
    font-size: 14px;
  }
  .custom-popover
    .custom-popover-header
    .header-left-content
    .forward-icon
    .shareButton
    svg {
    width: 15px;
  }
  .select-quantity-wrap {
    justify-content: space-between;
    /* margin-top: 20px; */
    /* height: 48px; */
    height: 32px;
    width: 100% !important;
  }
  .select-quantity-wrap .increase-quantity,
  .select-quantity-wrap .decrease-quantity {
    height: 100%;
  }

  .custom-popover.fade.show {
    display: block;
    opacity: 9;
    z-index: 99;
  }

  /* .custom-popover .popover {
    transform: translate(0, -190px) !important;
    top: 0 !important;
  }
  .custom-popover.fade {
    transition: none !important;
  }
   .custom-popover .popover-inner {
    position: relative;
    background-color: #fff;
    animation-name: slidein;
    animation-duration: 0.1s;
    transition: all 0.1s ease-out;
    top: 190px;
  } */

  @keyframes slidein {
    to {
      opacity: 1;
      -webkit-transform: translateY(0px);
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -o-transform: translateY(0px);
      transform: translateY(0px);
    }
    from {
      opacity: 0;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(400%);
      -o-transform: translateY(100%);
      transform: translateY(100%);
    }
  }

  .custom-popover .custom-popover-header .header-right-content svg path {
    fill: #171636;
  }
  /*.custom-popover
    .cart-product-card
    .cart-product-card-item
    .quantity-wrap
    .grocery-product-quantity
    .quantity-text {
    border-left: 2px solid #dadbe1;
    border-right: 2px solid #dadbe1;
    width: 70px;
  }
   .custom-popover
    .cart-product-card
    .cart-product-card-item
    .quantity-wrap
    .grocery-product-quantity {
    border: 2px solid #dadbe1;
  }
  .custom-popover .cart-product-card .cart-product-card-item {
    margin-bottom: 15px;
  }
   .custom-popover .cart-price-info-wrapper .cart-price {
    color: #0d0040;
  } 
  .custom-popover .cart-modal-bottom-content h6 {
    font-size: 16px;
    color: #0d0040;
  }
  .custom-popover .cart-modal-bottom-content .add-product-form .product-input {
    justify-content: space-between;
    background: #fff;
    border-radius: 8px;
    border: 2px solid var(--gray-menu, #dadbe1);
    margin-bottom: 16px;
  }
  .custom-popover .cart-modal-bottom-content .add-product-form .product-input .grocery-product-quantity {
    width: 60px;
    background-color: transparent;
    border-left: 2px solid var(--gray-menu, #dadbe1);
  }
  .custom-popover .cart-modal-bottom-content .add-product-form .product-input .product-name {
    width: auto;
  }*/
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .product-input
    .product-name
    input {
    border: 0 !important;
  }
  /* .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .product-input
    .product-name
    input,
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .form-group
    input {
    background-color: transparent !important;
    border: 2px solid var(--gray-menu, #dadbe1);
  }

   .custom-popover .cart-modal-bottom-content .add-product-form .price-input-wrapper .save-btn button {
    height: 48px;
    border-radius: 6px;
    background: #55b941 !important;
    border: 0 !important;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  } */

  .modal-footerBblock {
    top: 60px;
  }
  .main-modal .modal-footer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: auto !important;
    border-radius: 0 !important;
  }
  /* .main-modal .modal-footer button {
    flex-direction: row !important;
    gap: 10px;
    margin-right: 0 !important;
    height: auto !important;
    font-size: 16px !important;
    padding: 10px 16px !important;
    line-height: 18px;
    width: auto !important;
  }
  .main-modal .modal-footer button:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .main-modal .modal-footer button:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .main-modal .modal-footer button.active:after {
    content: unset;
  } */

  .main-modal .product-price-block {
    flex-direction: column;
  }
  .product-details-content {
    height: auto;
    overflow-x: unset;
    margin-bottom: 0;
  }
  .product-item-block {
    position: relative;
  }
  .product-item-block .product-price-block {
    margin-bottom: 44px;
  }
  .product-item-block .btn {
    height: 27px;
    font-size: 13px;
    /* position: absolute; */
    /* width: calc(100% - 30px); */
    margin: 0 auto;
    /* bottom: 20px; */
  }
  .product-item-block {
    margin-bottom: 0;
  }
  .categories-static h2 {
    font-size: 16px;
    align-items: center;
    flex-wrap: wrap;
  }
  .product-price-block {
    /* height: 60px; */
  }
  .categories-static.mobile-view .categories-static-header {
    display: block;
    padding: 0 10px;
    box-shadow: unset;
    margin-bottom: 0;
  }
  .categories-static.mobile-view .categories-static-header .tag-block {
    justify-content: flex-start;
  }
  .categories-static.mobile-view {
    margin-bottom: 0;
  }
  .filtered-products {
    /* margin-top: 100px !important; */
    /* margin-top: 60px !important; */
  }
  .categories-static {
    margin-bottom: 0;
  }
  .product-category-block .categories-static .categories-static-header {
    display: block;
    box-shadow: none;
    margin-bottom: 0px;
  }
  .product-category-block .categories-static {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }
  .product-category-block .categories-static button {
    display: none;
  }
  .noAnimation {
    padding-bottom: 70px;
    background: #f6f6f2;
  }
  .delete-modalBlock .modal-footer {
    flex-direction: column;
  }
  .delete-modalBlock .modal-header {
    justify-content: center;
  }
  .delete-modalBlock .modal-footer .btn {
    width: 100%;
  }
  .tag-block .tag-block-item img {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .category-block-inner {
    position: absolute;
    top: 0;
    height: 100%;
    background: #fff;
    transition: 0.3s;
    right: 0;
    width: 0;
  }
  .category-block-inner.active {
    width: 100%;
  }
  .category-inner-box {
    padding: 15px;
  }
  .category-inner-box a.back {
    text-decoration: none;
    color: #334a9c;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    font-family: Rubik;
  }
  .category-inner-box h2 {
    display: flex;
    align-items: center;
    gap: 15px;
    border-top: 1px solid #d7d7d7;
    padding: 30px 0;
    color: #0fa2ff;
    font-size: 14px;
    font-weight: bolder;
  }
  .category-name p.active {
    color: #334a9c !important;
  }
  .category-name p {
    margin-bottom: 30px !important;
    color: #151515 !important;
    text-align: left;
  }
  .category-name {
    max-height: calc(100vh - 352px);
    overflow: auto;
    direction: rtl;
  }
  .category-name::-webkit-scrollbar {
    width: 2px;
  }
  .category-name::-webkit-scrollbar-track {
    background: #e5e5e5;
  }
  .category-name::-webkit-scrollbar-thumb {
    background: #365da1;
  }
  .category-name::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .product-category-block .pointer.active {
    color: #0fa2ff !important;
    font-weight: bolder !important;
  }
  .product-item-list {
    color: #151515;
  }
  div#offcanvas-root > div {
    background-color: transparent !important;
  }
  .product-block-inner-mobile .font-icon {
    position: absolute;
    right: 10px;
    top: 7px;
    width: 20px;
    text-align: center;
    cursor: pointer;
    z-index: 9;
  }
  .categoriesPrice .product-price-block {
    margin-bottom: 0;
  }
  .categoriesPrice .offerPrice,
  .categoriesPrice .priceKg {
    font-size: 11px;
    height: 11px;
    line-height: 11px;
  }
  .categoriesPrice .product-price-block .price_span,
  .categoriesPrice .product-price-block .price_span span {
    font-size: 18px;
  }
  .sidebar-mobile .modelClose {
    position: absolute;
    top: 5px;
    right: 15px;
  }
  .select-quantity-wrap .show-quantity {
    font-size: 14px;
    min-width: unset;
  }
  .categories-static .categories-static-header {
    flex-wrap: wrap;
    gap: 15px;
  }
  .categories-static-inner {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .categories-static-inner .product-item-block-wrap {
    padding: 5px !important;
  }
}

@media screen and (min-width: 575px) {
  .main-modal .select-variety-mobile {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .main-modal .modal-innerBlock {
    display: block !important;
  }
  .main-modal .select-variety {
    display: none;
  }
  .main-modal .select-variety-mobile {
    display: block;
  }
  .main-modal .modal-footer button {
    flex-direction: row !important;
    gap: 10px;
    margin-right: 0 !important;
    height: auto !important;
    font-size: 16px !important;
    padding: 10px 16px !important;
    line-height: 18px;
    width: auto !important;
  }
  .main-modal .modal-footer button:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .main-modal .modal-footer button:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .main-modal .modal-footer button.active:after {
    content: unset;
  }
  .select-quantity-wrap {
    margin-top: 20px;
    height: 48px;
  }
  .modal.fade .modal-content .modal-body .product-details-content {
    padding-right: 16px !important;
  }
  .select-quantity-wrap .increase-quantity,
  .select-quantity-wrap .decrease-quantity {
    min-width: 56px;
  }
  .custom-popover .popover-inner {
    position: relative;
    background-color: #fff;
    animation-name: slidein;
    animation-duration: 0.1s;
    transition: all 0.1s ease-out;
  }
  .custom-popover .popover {
    transform: translate(0, -190px) !important;
    top: 0 !important;
  }
  .custom-popover.fade {
    transition: none !important;
  }
  .custom-popover .cart-price-info-wrapper .cart-price {
    color: #0d0040;
  }
  .custom-popover
    .cart-product-card
    .cart-product-card-item
    .quantity-wrap
    .grocery-product-quantity {
    border: 2px solid #dadbe1;
  }
  .custom-popover
    .cart-product-card
    .cart-product-card-item
    .quantity-wrap
    .grocery-product-quantity
    .quantity-text {
    border-left: 2px solid #dadbe1;
    border-right: 2px solid #dadbe1;
    width: 70px;
  }
  .custom-popover .cart-product-card .cart-product-card-item {
    margin-bottom: 15px;
  }
  .custom-popover .cart-modal-bottom-content h6 {
    font-size: 16px;
    color: #0d0040;
  }
  .custom-popover .cart-modal-bottom-content .add-product-form .product-input {
    justify-content: space-between;
    background: #fff;
    border-radius: 8px;
    border: 2px solid var(--gray-menu, #dadbe1);
    margin-bottom: 16px;
  }
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .product-input
    .product-name {
    width: auto;
  }
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .product-input
    .grocery-product-quantity {
    width: 60px;
    background-color: transparent;
    border-left: 2px solid var(--gray-menu, #dadbe1);
  }
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .product-input
    .product-name {
    width: auto;
  }
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .price-input-wrapper
    .save-btn
    button {
    height: 48px;
    border-radius: 6px;
    background: #55b941 !important;
    border: 0 !important;
    color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .product-input
    .product-name
    input,
  .custom-popover
    .cart-modal-bottom-content
    .add-product-form
    .form-group
    input {
    background-color: transparent !important;
    border: 2px solid var(--gray-menu, #dadbe1);
  }
  .main-modal .slick-slider {
    margin: 70px 0 0 0;
  }
  .sidebarMenuBlockMobile {
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
    display: block;
    position: fixed;
    width: 100%;
    top: 120px;
    z-index: 9;
  }
  .sidebarMenuBlockMobile .search-field {
    position: relative;
    width: 100%;
  }
  .sidebarMenuBlockMobile .search-field svg {
    position: absolute;
    right: 12px;
    top: 10px;
  }
  .sidebarMenuBlockMobile-item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  .sidebarMenuBlockMobile-item .btn-filter,
  .sidebarMenuBlockMobile-item .form-control {
    border: 1px solid #365da1;
    border-radius: 4px;
    box-shadow: none;
    background-color: #fff;
    height: 46px;
  }
  .sidebarMenuBlockMobile-item .form-control,
  .sidebarMenuBlockMobile-item .btn-filter {
    padding: 10px;
  }
  .product-banner-block,
  .categories-static .categories-static-header {
    display: none;
  }
  .product-banner-block {
    height: auto;
    /* margin: 100px 0 30px 0; */
    display: block;
    margin: 0 0 20px 0;
  }
  .productCategory-content {
    width: 100% !important;
    margin-top: 70px !important;
    /* margin-bottom: 70px !important; */
    /* top: 119px; */
    position: fixed;
    overflow: auto !important;
    height: calc(100vh - 240px);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .filtered-products {
    /* margin-top: 100px !important;
    margin-bottom: 70px !important; */
  }
  .tag-price-modal-block {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .product-item-block .btn {
    height: 27px;
  }
  .product-price-modal {
    margin-bottom: 11px;
    min-height: 57px;
    align-items: flex-end;
  }
  .product-item-Name {
    min-height: auto;
    margin-top: 12px;
  }
}
