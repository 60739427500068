@import "./sidebar";
@import "./helper";
@import "./fonts";
@import "./form";
@import "./charts";
@import "./table";
@import "./button";
@import "./typography";
@import "./reactstrapdoc";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
:root {
  --font-client: "Inter";
  --primary-dark: #151515;
  --primary-blue-rgb: 54, 96, 161;
  --primary-blue-accent: 15, 162, 255;
  --error-rgb: 223, 0, 0;
  --font-title: "Rubik";
}

body {
  overflow: hidden;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: #e7e7e7;
  border: 1px solid #eee;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #c4c2c2;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: var(--font-client);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.setting-button {
  position: fixed;
  top: 50%;
  z-index: 3;
  right: 0;
  // background-color: #241d3b !important;
  box-shadow: none;
  font-size: 20px !important;
  padding: 10px !important;
}

.arrow-sidebar {
  top: 12px;
  position: absolute;
  right: 5px;
  font-size: 14px !important;
  z-index: 1;
  cursor: pointer;
}

.Page-title {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  .title-icon {
    width: 45px;
    height: 45px;
    font-weight: 600;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 6px;
  }
  .title {
    margin-left: 14px;
    font-size: larger;
    font-weight: 600;
  }
}

.error-back {
  position: fixed;
  overflow: auto;
  top: 0;
  bottom: 0;
}

.error-container {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
    0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12) !important;
  text-align: center;
  padding: 20% 0;
  width: 100%;
  margin: 10% 0;
  .error-heading {
    font-size: 112px !important;
    font-weight: 300;
    line-height: 1 !important;
    letter-spacing: -0.04em !important;
    color: white;
  }
  .error-sub-title {
    font-size: 16px !important;
    font-weight: 400;
    color: white;
    padding: 20px 0;
  }
  .goHome {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color: #673ab7 !important;
    border-color: #673ab7 !important;
    color: white;
    padding: 9px 16px;
    border-radius: 30px;
    cursor: pointer;
    outline: 0;
  }
}

select.selectpicker {
  display: block;
  margin: 0 auto;
  padding-left: 20px;
}
.btn-new {
  background-color: #2a3f54;
}

.global-hash-title {
  font-size: 20px;
  font-weight: 600;
  margin: 30px 0;
}

.sweet-alert-confirm-button {
  color: #fff !important;
  background-color: #3f6ad8 !important;
  border-color: #3f6ad8 !important;
  padding: 10px 60px !important;
}

.sweet-alert-cancle-button {
  color: #fff !important;
  background-color: #d92550 !important;
  border-color: #d92550 !important;
  padding: 10px 60px !important;
}

.sweet-alert-wrapper {
  .lead {
    margin: 10px 0;
  }
  input {
    border-radius: 6px !important;
    font-size: 14px !important;
    border: 1px solid #ddd !important;
    &:focus {
      border: 1px solid #9a9a9a !important;
      box-shadow: none !important;
    }
  }
}

.icon-component {
  background: #eceff1;
  .icon {
    font-size: 36px;
    color: rgba(0, 0, 0, 0.54);
  }
  .text {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }
}

.profile-image {
  img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
}

.Profile-component {
  margin: -15px;
  .background {
    height: 250px;
  }

  .send-req-btn {
    position: absolute !important;
    top: 10px;
    right: 25px;
  }

  .profile-left-shade {
    position: relative;
    margin-top: -52px;
  }

  .profile-right-shade {
    background: white;
    position: relative;
    top: -25px;
  }

  .profile-list {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 10px;
    margin-right: 25px;
    cursor: pointer;
  }

  .feed-text-area {
    display: block;
    border-radius: 2px;
    min-width: 0;
    position: relative;
    text-decoration: none;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    background-color: #eee;
    border-color: #eee;
    padding: 16px;
    textarea {
      box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      background: #fff;
      &:focus {
        border-color: white;
      }
    }

    .feed-text-area-icon {
      i {
        font-size: 20px;
        margin-right: 15px;
      }
    }
  }

  .feed-card {
    margin: 0 auto;
    padding: 15px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    .feed-card-header {
      .feed-card-list-image {
        img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-top: -26px;
        }
      }
      .feed-post-name {
        font-size: 15px;
        font-weight: bold;
      }
      .feed-post-time {
        font-size: 12px;
        font-weight: 300;
      }
    }

    .feed-card-body {
      .body-description {
        font-size: 14px;
        font-weight: 300;
      }
      .feed-card-media {
        img {
          width: 100%;
          height: 350px;
        }
      }
    }
  }

  .Work-header {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    padding: 15px 15px;
    align-items: center;
  }

  .work-card {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    margin: 10px 0;
    .work-body {
      .Work-title {
        font-size: 15px;
        font-weight: 600;
      }

      .Work-text {
        font-size: 13px;
        font-weight: 300;
      }
    }
  }

  .see-all-btn {
    font-size: 12px;
    cursor: pointer;
  }

  .friend-image {
    width: 100%;
    object-fit: cover;
  }

  .profile-media-card {
    background-color: #f5f5f5 !important;
    padding: 25px;
    .media-image {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
    .media-text {
      position: absolute;
      top: 40%;
      text-align: center;
      width: 88%;
      color: white;
      font-weight: bold;
    }
  }
}

.language-popover-width {
  min-width: 15rem;

  .language-list-hover {
    cursor: pointer;
    &:hover {
      background: rgb(250, 250, 250);
    }
  }
}

.notification-popover-profile {
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}

.bs-popover-auto[x-placement^="bottom"] .arrow,
.bs-popover-bottom .arrow {
  top: calc((0.5rem + 0px) * -1) !important;
}
.bs-popover-auto[x-placement^="bottom"] .arrow::after,
.bs-popover-bottom .arrow::after {
  top: 1px;
  // border-bottom-color: #6200ea !important;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  width: 0 !important;
}

.grid-popover {
  i {
    font-size: 2.5rem !important;
    margin: 5px 0;
    opacity: 0.6;
  }
  .grid-text {
    font-size: 15px;
    font-weight: 600;
  }
  .col-border {
    border: 1px solid rgba(117, 117, 117, 0.2);
  }
}

.grid-popover i {
  font-size: 1rem !important;
}

.ul-bottom-border {
  border: 1px solid rgba(117, 117, 117, 0.2);
}

.gauge-card {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  padding: 16px;
}

.rasta-stripes {
  .react-datepicker__week:nth-child(3n + 1) {
    background-color: #215005;
  }
  .react-datepicker__week:nth-child(3n + 2) {
    background-color: #eea429;
  }
  .react-datepicker__week:nth-child(3n + 3) {
    background-color: #a82a15;
  }
}

.red-border {
  border-color: #f00;
}

.calender-back-class {
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
  background: white;
  border-radius: 10px;
  margin: 20px 0;
  .scheduler {
    margin: 6px auto !important;
  }
  .ant-radio-button-wrapper-checked {
    background-color: #6200ea !important;
    &:hover {
      color: white !important;
    }
  }
}

.my-table-custom-class {
  background-color: white !important;
  table {
    margin-bottom: 0;
  }
}

.chatUserDisplayHideOnLg {
  min-width: 300px;
  @media (max-width: 1199.98px) {
    display: none;
  }
}

.contactLeftPanelDisplayHideOnLg {
  min-width: 300px;
  @media (max-width: 1199.98px) {
    display: none;
  }
}

.searchStyle {
  margin-left: auto;
  @media (max-width: 575.98px) {
    max-width: 100%;
  }
  .close-search {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #6c757d;
  }
}

.filterSmallScreenmenu {
  display: none;
  @media (max-width: 1199.98px) {
    display: block;
  }
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.mini-drawer-menu-icon-hide-topbar {
  display: hide;
}

.popover-vehicle {
  font-size: 50px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.vehicle-icon-marker {
  font-size: 30px !important;
}

.roe-colors {
  display: flex;
  flex-direction: row;
  .color-left-side {
    width: 130px;
  }
  .color-right-side {
    flex: 1 0 0;
    padding-left: 10px;
  }
}

.react-strap-doc {
  padding: 0 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .profile-left-shade {
    margin-top: -200px !important;
  }

  .profile-right-shade {
    top: 0 !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .profile-left-shade {
    margin-top: -200px !important;
  }

  .profile-right-shade {
    top: 0 !important;
  }

  .drawer-handle-arrow-hide-topbar {
    display: none;
  }

  .mini-drawer-menu-icon-hide-topbar {
    display: block;
  }
}

@media only screen and (max-width: 575.98px) {
  .profile-left-shade {
    margin-top: -193px !important;
  }

  .profile-right-shade {
    top: 0 !important;
  }

  .send-req-btn {
    font-size: 12px;
  }

  .Profile-component {
    margin: -50px 0 -24px 0;
  }

  .display-1 {
    font-size: 3.5rem !important;
  }

  .display-2 {
    font-size: 3rem !important;
  }

  .display-3 {
    font-size: 2.5rem !important;
  }

  .display-4 {
    font-size: 2rem !important;
  }

  .feed-card-media {
    img {
      height: 250px !important;
    }
  }

  .react-strap-doc {
    padding: 0 15px;
  }

  .header-popover-width {
    min-width: 22rem;
  }

  .drawer-handle-arrow-hide-topbar {
    display: none;
  }

  .mini-drawer-menu-icon-hide-topbar {
    display: block;
  }

  .pa-0-small-screen {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.mobile-ma-8 {
  @media (max-width: 575.98px) {
    margin-top: 12px;
  }
}

/* New Design */
.btn-green {
  background-color: #58b94b !important;
  color: #fff !important;
  border-color: #58b94b !important;
  &:hover {
    background-color: transparent !important;
    color: #58b94b !important;
    border-color: #58b94b !important;
  }
}
.btn-primary-outlined {
  background: transparent !important;
  border: 1px solid rgba(var(--primary-blue-rgb), 1) !important;
  color: rgba(var(--primary-blue-rgb), 1) !important;
  &:hover {
    background-color: rgba(var(--primary-blue-rgb), 0.05) !important;
  }
}
.modal-footer {
  .product-modal-footer-btn {
    background: rgba(var(--primary-blue-rgb), 0.05);
    border: 1px solid transparent;
    font-size: 16px;
    font-weight: 400;
    color: #0d0040;
    margin: 0 !important;
    font-family: "Rubik";
    padding: 4px 12px;
    transition: all 300ms ease-in-out;

    &:before {
      content: "";
      position: absolute;
      left: -1px;
      top: 50%;
      width: 1px;
      height: calc(100% - 10px);
      background: #c9c9c9;
      transform: translateY(-50%);
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
      &:before {
        content: none;
      }
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    svg {
      fill: #0d0040;
      margin-left: 10px;
    }
    &.disabled {
      pointer-events: none;
      span,
      svg {
        opacity: 0.3;
      }
    }
    &.active,
    &:hover {
      background: rgba(var(--primary-blue-rgb), 1);
      color: #fff;
      border-radius: 8px;
      svg {
        fill: #fff;
      }
    }
  }
}

.customProduct {
  flex-wrap: wrap;
  &:hover {
    background-color: var(--hover-color);
    border-radius: 8px;
  }
  .custom-marker {
    background: var(--primary-color);
    border-radius: 100%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 4px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25px;
    .icon-marker {
      width: 100% !important;
      height: 100%;
      line-height: normal;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.delete-icon {
  span {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #df0000;
    font-size: 18px;
    cursor: pointer;
    @media (max-width: 767px) {
      width: 32px;
      height: 32px;
    }
  }
}

.custom-popover {
  background: #fff;
  border: none;
  .popover-inner {
    padding: 0 25px 25px;
    height: 70vh;

    @media (max-width: 575px) {
      height: 100vh;
      padding: 0 0 30px;
    }
  }
  .custom-popover-header {
    display: flex;
    border: none;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 23px 0;

    .header-left-content,
    .header-right-content {
      min-width: 40px;
    }
    .header-left-content {
      .forward-icon {
        color: rgba(var(--primary-blue-rgb), 1);
        .shareButton {
          border: 1px solid #365da1;
          border-radius: 6px;
          padding: 0 5px;
          height: 30px;
          // width: 38px !important;
        }
        @media (max-width: 575px) {
          .forward-text {
            display: flex !important;
            padding-right: 10px;
          }
        }
      }
    }
    .header-right-content {
      text-align: right;
      svg {
        cursor: pointer;
        font-size: 24px;
        @media (max-width: 767px) {
          fill: red;
          width: 26px;
        }
      }
    }

    .title {
      font-size: 18px;
      font-family: "Rubik";
      font-weight: 600;
      color: #0d0040;
      @media (max-width: 767px) {
        display: none;
      }
    }
    @media (max-width: 767px) {
      padding-left: 12px;
      padding-right: 12px;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
      margin-bottom: 14px !important;
    }
  }
  .popover-body {
    padding: 0;
    height: calc(100% - 66px);
    @media (max-width: 767px) {
      height: calc(100% - 140px) !important;
      overflow: auto;
    }
  }
  .cart-card-wrapper {
    height: calc(100% - 240px);
    overflow-y: auto;
    overflow-x: visible;
    flex-grow: 1;
    padding: 0 4px;
    @media (max-width: 575px) {
      padding: 30px 30px 10px;
      height: auto;
      overflow: unset;
    }
  }
  .cart-product-card {
    margin: 0 0 16px;
    padding: 0;
    list-style: none;
    .cart-product-card-item {
      padding: 10px;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      position: relative;
      .product-card-item-wrap {
        display: flex;
        gap: 22px;
        align-items: center;
        @media (max-width: 575px) {
          // flex-wrap: wrap;
        }
      }
      .product-details-wrap {
        display: flex;
        gap: 10px;
        width: calc(100% - (70px + 80px));
        @media (max-width: 575px) {
          flex-wrap: wrap;
          width: calc(100% - 116px);
        }
      }
      .product-image {
        img {
          width: 80px;
          min-width: 80px;
          @media (max-width: 575px) {
            width: 100px;
            min-width: 100px;
          }
        }
      }
      .product-details {
        color: #0d0040;
        width: calc(100% - 140px);
        min-width: calc(100% - 140px);
        @media (max-width: 767px) {
          width: calc(100% - 110px);
          min-width: calc(100% - 110px);
        }
        h6 {
          font-weight: 400;
          overflow-wrap: anywhere;
        }
        .price {
          font-weight: 900;
          font-size: 20px;
        }
        @media (max-width: 575px) {
          width: 100%;
          min-width: 100%;
        }
      }
      .quantity-wrap {
        min-width: 140px;
        @media (max-width: 767px) {
          min-width: 120px;
        }
        .grocery-product-quantity {
          .quantity-text {
            @media (max-width: 575px) {
              padding: 4px 10px;
              position: relative;
              border-left: 1px solid #dadbe1;
              border-right: 1px solid #dadbe1;
            }
          }
          @media (max-width: 575px) {
            height: auto;
            border: 1px solid #dadbe1;
            border-radius: 8px;
            height: auto;
            padding: 0 10px !important;
            border: 1px solid #dadbe1;
            border-radius: 8px;
            justify-content: space-between;
          }
        }
      }

      .delete-icon-wrap {
        width: 70px;
        min-width: 70px;
        display: flex;
        justify-content: flex-end;
        padding: 0 14px;
        @media (max-width: 575px) {
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 0;
          span {
            width: 24px;
            min-width: 24px;
            height: 24px;
            font-size: 14px;
          }
        }
      }
    }
  }
  .cart-price-info-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 20px 0;
    .total-text {
      font-size: 18px;
    }
    .cart-price {
      display: flex;
      align-items: flex-end;
      gap: 15px;
      flex-wrap: nowrap;
      .total-price {
        font-size: 28px;
        white-space: nowrap;
        font-weight: 800;
        line-height: 35px;
        white-space: nowrap;
      }
    }
    @media (max-width: 767px) {
      // padding: 0 30px;
      & > div {
        width: 100%;
        justify-content: space-between;
      }
    }
    @media (max-width: 575px) {
      flex-direction: column;
      padding: 0 30px;
    }
  }
  .cart-modal-bottom-content {
    padding-top: 20px;
    border-top: 1px solid #d7d7d7;
    h6 {
      font-size: 18px;
      font-weight: 500;
      font-family: "Rubik";
      margin-bottom: 20px;
    }
    .add-product-form {
      .form-group {
        input {
          background: rgba(var(--primary-blue-rgb), 0.05);
          border: 1px solid transparent;
          border-radius: 8px;
          min-height: 42px;
          font-family: "Rubik";
          &::placeholder {
            color: rgba(var(--primary-blue-rgb), 0.4);
          }
        }
      }
      .product-input {
        margin-bottom: 10px;
        .product-name {
          width: calc(100% - 140px);
          input {
            border-radius: 8px 0 0 8px;
          }
        }
        .grocery-product-quantity {
          width: 140px;
          margin: 0;
          border: none;
          padding: 0;
          min-width: unset;
          height: auto;
          background: rgba(var(--primary-blue-rgb), 0.05);
          border-radius: 0 8px 8px 0;
          h6 {
            margin-bottom: 0;
          }
        }
      }
      .price-input-wrapper {
        .form-group {
          width: calc(100% - 150px);
          @media (max-width: 575px) {
            width: 100%;
            margin-bottom: 16px;
          }
        }
        .save-btn {
          button {
            min-width: 140px;
          }
          @media (max-width: 575px) {
            width: 100%;
            button {
              width: 100%;
            }
          }
        }
      }
    }
    @media (max-width: 575px) {
      margin: 0 30px;
    }
  }
}
.listingBox {
  text-align: center;
}
.order-btn {
  text-align: right;
  .btn {
    padding: 5px 10px;
    height: auto;
    font-size: 16px;
    line-height: normal;
    margin-left: auto;
    @media (max-width: 575px) {
      padding: 9px 10px;
      width: 100%;
      height: 48px;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.grocery-product-quantity {
  color: #0d0040;
  gap: 10px;
  margin-left: 0;
  float: none !important;
  padding: 0 !important;
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  height: 80px;
  h6 {
    font-size: 16px;
    font-weight: 700;
  }
  .quantity-icon {
    display: inline-flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border: 1px solid #0d0040;
    border-radius: 100%;
    font-size: 10px;
    color: #0d0040;
    @media (max-width: 575px) {
      &.xs-none {
        display: none;
      }
    }
  }
  &.productquantity {
    .quantity-icon {
      @media (max-width: 575px) {
        width: auto;
        height: auto;
        border: none;
      }
    }
  }
}

#list-cart > div:has(.cart-item-badge) {
  position: absolute !important;
}

.mobile-footer {
  position: fixed;
  bottom: 0;
  z-index: 9;
  background: #fff;
  left: 0;
  width: 100%;
  height: 50px;
  padding: 0 24px;
  box-shadow: 0 -6px 14px rgba(0, 0, 0, 0.15);
  .footer-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    align-items: center;
    .footer-nav-items {
      width: 32px;
      text-align: center;
      z-index: 111;
    }
    button {
      background: transparent;
      border: none;
      width: 24px;
      padding: 0;
      position: relative;
      svg {
        width: 24px;
        color: #000;
      }
      &.menu-icon {
        width: 32px;
        svg {
          width: 32px;
        }
      }
      .notificatopn-badge {
        position: absolute;
        background: #df0000;
        width: 15px;
        height: 15px;
        font-size: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: -7px;
        border-radius: 100%;
      }
    }
  }
  .footer-sub-nav {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    list-style: none;
    background: #fff;
    bottom: 45px;
    padding: 10px 24px;
    box-shadow: 0 -6px 6px rgba(0, 0, 0, 0.2);
    z-index: 111;
    &.closed {
      display: none;
    }
    li {
      padding: 12px 0;
      border-bottom: 1px solid rgba(var(--primary-blue-rgb), 1);
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
      &:last-child {
        border: none;
      }
      svg {
        width: 24px;
      }
    }
  }
}
.user-profile-mobile {
  max-width: 40px;
  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 3px;
  }
}
.mobile-header {
  padding: 16px 16px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  // box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
  left: 0;
  .mobile-top-bar {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    position: relative;
    .left-content {
      width: auto;
    }
    .right-content {
      width: auto;
    }
    .middle-content {
      width: calc(100% - 80px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .logo {
        text-align: center;
        img {
          height: 38px;
          width: auto;
        }
      }
    }
    .left-content {
      .icon {
        color: #8f61db;
      }
    }
    .cart-icon {
      position: relative;
      display: flex;
      justify-content: flex-end;
      svg {
        transform: scaleX(-1);
      }
      .notificatopn-badge {
        position: absolute;
        background: #df0000;
        width: 15px;
        height: 15px;
        font-size: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: -7px;
        border-radius: 100%;
      }
    }
  }
  .filter-wrap {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 13px;
    li {
      // width: calc(50% - 10px);
      text-align: center;
      border: 1px solid var(--primary-color);
      border-radius: 6px;
      color: var(--primary-color);
      font-family: "Rubik";
      transition: all 300ms ease-in-out;
      padding: 3px 8px;
      line-height: normal;
      width: 100%;
      &.active {
        background: var(--primary-color);
        color: #ffffff;
      }
    }
  }
}

.modal-footer::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

.modal-footer::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: #e7e7e7;
  border: 1px solid #eee;
}

.modal-footer::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #c4c2c2;
}

.modal.fade {
  &:has(.custom-bs-modal) {
    overflow-y: hidden;
  }
  .modal-dialog {
    &.custom-bs-modal {
      transform: translate(0, 50px) !important;
      @media (max-width: 575px) {
        align-items: flex-end;
        width: 100%;

        .modal-content {
          height: 85vh;
          .modal-body {
            .product-details-content {
              padding-top: 18px;
            }
          }
          .add-cart-modal-btn {
            width: 100% !important;
            margin-top: 28px;
            margin-bottom: 0 !important;
          }
          .modal-price-wrap {
            padding-bottom: 10px;
            border-bottom: 1px solid #d7d7d7;
            .price_span {
              font-size: 45px !important;
            }
          }
        }
      }
      .modal-footer {
        height: 40px;
        width: 100%;
        padding: 0 0 0;
        background: #fff;
        z-index: 1;
        overflow-x: auto;
        flex-wrap: nowrap;
        border-radius: 0;
        .product-modal-footer-btn {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row !important;
          align-items: center;
        }
      }
    }
  }
  .show {
    .modal-dialog {
      &.custom-bs-modal {
        transform: none !important;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}
.product-item-block {
  .queProductBox {
    display: flex;
    align-content: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
    @media (max-width: 768px) {
      // position: absolute;
      // width: calc(100% - 30px);
      // margin: 0 auto;
      // bottom: 20px;
    }
    & > button {
      width: auto;
      padding: 4px 15px !important;
      background: transparent;
      &:hover {
        background: transparent;
      }
      @media (max-width: 768px) {
        padding: 2px 15px !important;
        position: static;
        margin: 0;
      }
      @media (max-width: 575px) {
        display: none;
      }
    }
    .show-quantity {
      min-width: 55px;
      padding: 0 2px;
      font-size: 14px;
      @media (max-width: 768px) {
        min-width: 37px;
      }
    }
    .select-quantity-wrap .increase-quantity,
    .select-quantity-wrap .decrease-quantity {
      min-width: 32px;
      @media (max-width: 768px) {
        min-width: 24px;
        height: 25px;
      }
    }
    .select-quantity-wrap {
      // max-width: 155px;
      @media (max-width: 991px) {
        margin-top: 0;
        height: auto;
        width: 100% !important;
        margin-bottom: 0 !important;
        max-width: 100%;
        height: 34px;
      }
      @media (max-width: 768px) {
        height: 27px;
      }
      @media (max-width: 600px) {
        max-width: 100%;
      }
    }
  }
}
#sidebar {
  overflow: hidden !important;
}
@media (max-width: 991px) {
  .main-panel {
    // height: calc(100vh - 280px) !important;
    // margin-top: 120px;
    .bodyHeight {
      // padding-right: 0 !important;
      // overflow-y: visible !important;
      height: auto !important;
    }
    .H_not_adsense_mobile_detected {
      // height: auto !important;
    }
  }
  .page-scroll .route-height {
    // padding: 0 !important;
  }
}
// @media (max-height: 1200px) {
//   .main-panel {
//     height: calc(100vh - 280px) !important;
//     margin-top: 120px;
//     .bodyHeight {
//       padding-right: 0 !important;
//       overflow-y: visible !important;
//       height: auto !important;
//     }
//     .H_not_adsense_mobile_detected {
//       height: auto !important;
//     }
//   }
//   .page-scroll .route-height {
//     padding: 0 !important;
//   }
// }
@media only screen and (min-height: 500px) and (max-height: 800px) {
}

@media (min-width: 992px) {
  .mainSidebar {
    min-width: 75px;
    width: 75px;
    max-width: 75px;
    overflow: hidden !important;
  }
  #list-cart {
    width: 61px !important;
    overflow: hidden !important;
  }
  .sidebar-wrapper {
    .sidebarMainMenu {
      position: relative;
      // padding: 84px 0;
      ul {
        // max-height: calc(100vh - 60px);
        // height: 100%;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #fff;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #fff;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #fff;
        }
      }
      // li:first-child {
      //   position: absolute;
      //   top: 0;
      //   border-bottom: 2px solid #eee;
      // }
      // li:last-child {
      //   position: absolute !important;
      //   bottom: 0;
      //   border-top: 2px solid #eee;
      // }
    }
  }
}
.sidebar-wrapper {
  .sidebarMainMenu {
    position: relative;
    // height: calc(100% - 338px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: 1199px) {
      height: calc(100vh - 200px);
    }
    @media (max-width: 767px) {
      height: calc(100vh - 151px);
    }
    ul {
      overflow-y: auto;
      border-bottom: 3px solid #d7d7d7;
      border-top: 3px solid #d7d7d7;
      margin: 10px 0;
      max-height: calc(100vh - 200px);
      @media (max-width: 1299px) {
        // height: calc(100% - 339px);
        height: auto;
        //max-height: 50vh;
        min-height: 60px;
      }
      &::-webkit-scrollbar {
        width: 0px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #fff;
      }
    }
  }
}
.sidebar-nav-fix {
  .nav-link {
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    transition: all 300ms ease-in-out;
    border-radius: 8px;
    color: #151515 !important;
    cursor: pointer;
    span {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      display: inline-block;
    }
    &:hover,
    &.active {
      background-color: rgba(var(--primary-blue-rgb), 0.05);
      color: var(--secondary-color) !important;
      .nav-item-label {
        font-weight: 600;
      }
    }
    // &.active {
    //   background-color: rgba(var(--primary-blue-rgb), 0.05);
    //   span {
    //     font-weight: 600;
    //   }
    // }
    .cart-badge-main {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .cart-item-badge {
      position: absolute;
      display: flex;
      width: 20px;
      height: 20px;
      bottom: 55px !important;
      top: unset !important;
      right: 5px !important;
      color: #fff;
      background: rgba(var(--error-rgb), 1);
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      // font-size: 1rem;
      font-weight: 500;
      padding: 0 !important;
      width: 20px;
      height: 20px;
      border-radius: 100% !important;
      display: flex !important;
      font-size: 11px !important;
    }
  }
}
// landscape Views css
// .mobile-header {
//   display: none;
//   @media (max-width: 575px) {
//     display: block;
//   }
// }
// @media screen and (max-width: 991px) and (orientation: landscape) {
//   .mobile-header {
//     display: block;
//     padding: 16px 16px;
//     background: #fff;
//     position: fixed;
//     top: 0;
//     z-index: 1;
//     width: 100%;
//     box-shadow: 0 4px 14px rgba(0, 0, 0, 0.15);
//     left: 0;
//   }
//   #sidebar,
//   #pagers {
//     display: none;
//   }
// }
.page-scroll {
  > div {
    &:first-child {
      // margin-right: -17px !important;
      margin-bottom: -17px !important;
      @media (max-width: 575px) {
        width: 100%;
        // margin-right: -8px !important;
      }
    }
  }
}
