/* *********************** Custom Color *********************** */

@font-face {
  font-family: "Koulen";
  font-weight: "regular";
  font-style: "regular";
  src: url(../font/Koulen-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "M PLUS Rounded 1c";
  font-weight: "regular";
  font-style: "regular";
  src: url(../font/MPLUSRounded1c-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Frutiger";
  font-weight: "regular";
  font-style: "regular";
  src: url(../font/FrutigerNextCom-RegularCn.ttf) format("truetype");
}

/* @font-face {
  font-family: 'Frutiger';
  font-weight: "bold";
  font-style: "bold";
  src: url(../font/FrutigerNextCom-BoldCn.ttf) format("truetype");
} */

:root {
  --primary-color: #cccccc;
  --secondary-color: #cccccc;
  --tertiary-color: #cccccc;
  --hover-color: #cccccc;
  --viewport-height: 100%;
  --background-color: #fff;
  --h-adsense: 90px;
  --h-header-lg: 53px;
  --h-header: 48px;
  --h-bottom-bar: 42px;
  --h-pager: 50px;
  --hover-sidebar-color: #cccccc;
  --font-client: "M PLUS Rounded 1c";
  --color-interattivo-primary: #008dda;
}

/* ************************************************************ */

.bodyStyle {
  background-color: var(--background-color);
}

.inpublish-icon-container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  margin: 0 20px 0 15px;
  width: 113px;
}

.notDisponibile {
  background: url("../images/non-disponibile.png");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.disableButton {
  background-color: "grey";
}

.shareIcon:hover::after {
  font-size: 10px;
  background-color: grey;
  color: white;
  position: absolute;
  padding: 3px;
  top: -5px;
  border-radius: 2px;
  border: 1px solid white;
}

.emailShareIcon:hover::after {
  content: "Email";
}

.whatsappShareIcon:hover::after {
  content: "Whatsapp";
}

.telegramShareIcon:hover::after {
  content: "Telegram";
}

.copyButton:hover::after {
  content: "Copy List Link";
}

.copyButtonVolantino:hover::after {
  content: "Copy Flyer Link ";
}

.facebookShareButton:hover::after {
  content: "Facebook";
}

.twitterShareIcon:hover::after {
  content: "Twitter";
}

/* CAROUSEL ARROW */
.awssld__next,
.awssld__prev {
  display: none !important;
}

.open-sidebar-icon {
  background-color: var(--primary-color);
  border-radius: 100%;
}

.open-sidebar-icon i {
  color: white;
}

/* .awesome_slider_style {
  height: calc(100% - 50px) !important;
} */

@media (max-width: 991px) {
  .awesome_slider_style {
    height: 100% !important;
  }
}

.onlyShopping {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-alpha-color);
  height: 55px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  padding: 0px 10px;
}

.slider {
  width: 95% !important;
  margin: auto;
}

.marker {
  cursor: pointer;
  z-index: 0;
}

#bottomSideBar {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--primary-color) !important;
  z-index: 100;
}

#bottomSideBar ul {
  justify-content: center;
}

#bottomSideBar ul li,
#bottomSideBar .width20 {
  width: 20%;
  text-align: center;
}

#bottomSideBar ul li a.active {
  background-color: var(--secondary-color) !important;
}

#bottomSideBar a {
  color: white;
}

#bottomSideBar a i {
  font-size: 20px;
  line-height: 24px;
}

#bottomSideBar a p {
  display: none;
}

.mini-drawer-menu-icon {
  color: var(--primary-color) !important;
}

.noRadius {
  border-radius: 0 !important;
}

.sidebar {
  background-color: white !important;
}

/* .right-sidebar {
    background-color: var(--primary-color) !important;
} */

.right-sidebar-hint-icon > svg {
  color: var(--secondary-color) !important;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid var(--secondary-color) !important;
}

.nav-link .main-list .active {
  color: var(--secondary-color) !important;
}

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.btn-secondary {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: #ffffff !important;
}

.customProduct {
  cursor: pointer;
}

/* .customProduct:hover {
  background-color: var(--hover-color);
} */

.customLink:hover {
  background-color: var(--hover-color);
}

.slider-control-bottomcenter {
  bottom: -40px !important;
}

.carousel-arrow-right {
  position: absolute;
  right: 0;
}

.carousel-arrow-left {
  position: absolute;
  left: -0.5rem;
}

.carouselLoader {
  background-color: white;
}

.carousel-arrow-right:hover {
  cursor: pointer;
}

.carousel-arrow-left:hover {
  cursor: pointer;
}

/* .carousel-pagination-button {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
} */

.carousel-pagination-button:not(:disabled):not(.disabled).active {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}

#modalCarousel .carousel-root .carousel:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  width: 80px;
  display: none;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: var(--primary-color) !important;
}

.carousel.carousel-slider .control-arrow {
  background: var(--primary-color) !important;
  background: white !important;
  opacity: 1 !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid var(--primary-color) !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid var(--primary-color) !important;
}

.carousel .control-dots .dot {
  background: var(--secondary-color) !important;
  opacity: 1;
}

.carousel.carousel-slider {
  overflow: visible;
}

.carousel .control-dots {
  bottom: -50px;
  padding-inline-start: 0;
}

#modalCarousel .carousel-root .carousel:nth-child(2)::-webkit-scrollbar {
  /* display: none; */
}

#modalCarousel .carousel-root {
  /*  position: relative; */
}

#modalCarousel {
  position: relative;
  overflow: hidden;
  flex-basis: auto;
}

#modalCarousel .carousel .thumbs-wrapper {
  margin: 0;
}

#modalCarousel .carousel .thumbs {
  margin-block-start: 0em;
  margin-block-end: 0em;
  padding-inline-start: 0px;
  transform: translate3d(0, 0, 0) !important;
}

#modalCarousel .carousel .thumbs .thumb {
  display: block;
  border: 0 !important;
  opacity: 0.4;
  height: 73px;
}

#modalCarousel .carousel .thumbs .thumb {
  max-height: 73px;
}

#modalCarousel .carousel .thumbs .thumb.selected {
  border: 0 !important;
  opacity: 0.9;
}

.aggiungiButtonText {
  color: var(--primary-color) !important;
  font-weight: bold;
  font-size: 13px;
}

/* Icon marker */

.icon-marker {
  width: 25px;
}

.icon-marker-large {
  width: 28px;
}

.marker[type="plus"] .icon-marker {
  width: 23px;
  /* width: 30px; */
}

.icon-marker-promo {
  width: 25px;
}

#indiceId .btn-group {
  display: inline-block;
}

/*Carousel modals markers*/

.carousel .slide img {
  width: auto !important;
}

.subcat_margin {
  margin-left: 57px !important;
}
@media screen and (min-width: 575px) {
  .grocery-list-popover .popover.bs-popover-auto {
    top: 10px !important;
    height: calc(100vh - 75px);
  }
}
@media (max-width: 767px) {
  .carousel .slide img {
    width: auto !important;
  }

  #modalCarousel .carousel .thumbs .thumb {
    max-height: 55px;
  }

  .mini-drawer-menu-icon .fa-bars,
  #mobile-search-hidden {
    display: none;
  }

  #fullScreenId {
    display: none !important;
  }
}

.wrapText {
  white-space: pre-wrap;
  /* max-height: 200px; */
}

@media (min-width: 768px) {
  .wrapText {
    /* max-height: 400px; */
    overflow-y: auto;
  }
}

@media (min-width: 1200px) {
  .wrapText {
    /* max-height: 600px; */
    overflow-y: auto;
  }
}

.wrap_text_p {
  padding-bottom: 50px;
}

.wrap_text_p_ios {
  padding-bottom: 70px;
}

.carousel .slide {
  background-color: white !important;
}

.carousel .thumb img {
  max-height: 50px !important;
  width: auto !important;
}

.carousel .thumb {
  text-align: center !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  /* border: 2px solid var(--primary-color) !important; */
  border: 3px solid var(--primary-color) !important;
}

.carousel .slider {
  width: 100% !important;
}

/*Right Sidebar*/

/* .right-sidebar-header {
  cursor: pointer;
  position: relative;
  z-index: 4;
  padding: 8px 3px 3px 3px;
}

.right-sidebar-img-logo {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  object-fit: contain;
}

.right-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  display: block;
  z-index: 3;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  overflow-x: hidden;
  background-position: center center;
}

.right-sidebar .right-sidebar-wrapper {
  position: relative;
  height: calc(100vh - 80px);
  z-index: 4;
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
} */

#h5total {
  line-height: 37px;
}

/* .right-sidebar .nav li {
  width: 100%;
}

.right-sidebar .nav li .card .card-body a {
  color: #ffffff;
  opacity: 0.86;
  border-radius: 4px;
  margin: 5px 10px 0;
}

.right-sidebar .nav li > a {
  color: #ffffff;
  opacity: 0.86;
  border-radius: 4px;
  margin: 5px 3px 0;
  padding: 0.5rem 0.7rem !important;
}

.right-sidebar .nav .caret {
  top: 24px;
  position: absolute;
  right: 15px;
}

.right-sidebar .nav .span-shortname {
  font-size: 12px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

.right-sidebar .nav i {
  font-size: 25px;
  float: left;
  line-height: 40px;
  width: 25px;
  text-align: center;
  border: 1px #ffffff solid;
  border-radius: 50%;
}

.right-sidebar .nav img {
  font-size: 25px;
  margin-right: 15px;
  line-height: 40px;
  width: 50px;
  text-align: center;
} */

body > .navbar-collapse:after,
body > .navbar-collapse:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

#desktop-grocery {
  width: 40px;
}

.custom-grocery-notification-badge {
  background-color: var(--primary-color) !important;
  top: -41px !important;
  right: -3px !important;
}

.custom-mobile-grocery-notification-badge {
  background-color: var(--secondary-color) !important;
  /* top: -23px !important; */
  /* right: -5px !important; */
  /* top: 0px !important;
  right: 1px !important; */
  top: -3px !important;
  right: -5px !important;
  border-radius: 360px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.main-panel {
  background: #f8fafd;
  position: relative;
  float: right;
  width: calc(100% - 260px);
  height: 100vh;
}
.withoutsidebarpanel {
  width: calc(100% - 75px) !important;
  float: unset;
  margin: auto;
}
.link-type-interactivity {
  height: calc(100% - 25px);
  position: relative;
}
.link-type-interactivity h6 {
  font-size: 40px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 10px;
  margin-left: 20px;
}
.link-type-interactivity img {
  object-fit: contain;
  max-height: calc(100vh - 480px);
  width: 100%;
}
.link-type-interactivity a {
  border: 1px solid rgb(13, 0, 64);
  color: rgb(13, 0, 64);
  padding: 10px 16px;
  border-radius: 12px;
  text-align: center;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  min-width: 150px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 991px) {
  .main-panel {
    /* height: calc(var(--viewport-height) - 55px) !important; */
  }
  .withoutsidebarpanel {
    width: 100% !important;
  }
  .link-type-interactivity h6 {
    margin-top: 30px;
  }
}

/*Categories List*/

.styleScrollableCategories {
  width: 95% !important;
  overflow: auto;
  padding-bottom: 110px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.noProducts {
  margin-top: 0;
}

@media (max-width: 991px) {
  .styleScrollableCategories {
    height: calc(100vh - 66px);
    margin-top: 0;
  }

  /* .noProducts {
    margin-top: 215px;
  } */
}

.icon-input-group {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.search-button-container {
  margin-top: 15px;
  text-align: center;
}

.search-button-container button {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.custom-card-header {
  min-height: 40px;
}

.custom-product-price-varieties {
  display: flex;
  justify-content: space-between;
}

.custom-product-price-varieties p {
  font-weight: bold;
  font-size: 2.5vw;
  white-space: nowrap;
  overflow: hidden;
}

.custom-product-price-varieties span {
  font-weight: bold;
}

.button-send-to-cart {
  width: 65px;
  /* border-radius: 50% !important; */
  height: 65px;
}

.button-send-to-cart svg {
  font-size: 25px !important;
  width: 100% !important;
}

@media screen and (max-width: 1399px) {
  .button-send-to-cart {
    width: 50px;
    height: 50px;
  }

  .button-send-to-cart svg {
    font-size: 25px !important;
    width: 100% !important;
  }
  .container-slide .carousel_img_modal {
    height: calc(80vh - 200px);
  }
}

@media screen and (max-width: 1199px) {
  .button-send-to-cart {
    width: 65px;
    height: 65px;
  }
}

@media screen and (max-width: 767px) {
  .margin-mobile {
    margin-bottom: 10px;
  }

  .custom-product-price-varieties p {
    font-size: 12vw;
  }
}

/*Floating grocery list*/

.floating-grocery-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99999;
  width: 80px;
  height: 80px;
  background-color: var(--tertiary-color) !important;
  border-radius: 50% !important;
  box-shadow: 0 0 10px #000000 !important;
  border: none !important;
}

.floating-grocery-list {
  position: fixed;
  right: 50px;
  bottom: 150px;
  width: 300px;
  height: 500px;
  z-index: 99999;
  background-color: #ffffff;
  box-shadow: 0 0 10px #000000 !important;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.floating-grocery-list > .visible {
  display: block;
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 2s;
}

.floating-grocery-list > .invisible {
  display: none;
}

.mobile-grocery-list {
  display: none !important;
}

/*Notification*/

.custom-notification {
  background-color: var(--primary-color) !important;
  color: #ffffff;
}

/*Custom form*/

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

/*Modal plus*/

.modal-plus-mobile {
  display: none;
}

.modal-plus-desktop {
  display: block;
}

@media (max-width: 991px) {
  .modal-plus-mobile {
    display: block;
  }

  .modal-plus-desktop {
    display: none;
  }
}

@media screen and (min-width: 767.98px) {
  /*Search bar*/
  .custom-search-box {
    display: flex;
  }

  .custom-search-li {
    /* width: 100%; */
    display: flex !important;
    justify-content: center;
    align-content: center;
  }

  .custom-search-li > .input-group {
    /* width: 50%; */
  }

  .custom-mobile-grocery-list-image {
    display: none !important;
  }
}

.custom-search-li > .input-group .input-group-text {
  cursor: pointer;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  color: #ffffff !important;
}

.image-pages-list {
  width: 100%;
  cursor: pointer;
}

.custom-search-bar-wrapper {
  /* padding: 40px 15px; */
  padding: 0 10px;
}

/* Grocery List */

.popover {
  z-index: 1040 !important;
  border-radius: 10px !important;
  border: none !important;
}

/* .custom-popover {
  transform: translate3d(1468px, 121px, 0px) !important;
} */

.popover-body {
  display: flex;
  flex-direction: column;
}

.custom-scrollbars {
  order: 1;
  /* height: 70vh !important; */
}

@media screen and (max-height: 570px) {
  .custom-scrollbars {
    order: 1;
    height: 50vh !important;
  }
}

.custom-total-price {
  display: flex;
  order: 2;
  color: #ffffff;
}

.giodicartRelated .alice-carousel {
  width: 85%;
}

.custom-popover-footer {
  order: 3;
  width: 100%;
  background-color: #ffffff;
}

.custom-grocery-item {
  padding: 15px 15px 15px 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  /*border-radius: 6px;
    margin-bottom: 3vh;*/
  border-bottom: rgba(0, 0, 0, 0.2) 1px solid;
}

.custom-grocery-back-btn {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background-color: var(--primary-color);
  border-radius: 30px;
  margin-bottom: 3vh;
  cursor: pointer;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-grocery-product-info-container {
  min-height: 85%;
}

.custom-grocery-back-btn span {
  color: #ffffff;
}

.custom-container {
  background-color: rgb(250, 250, 250);
}

.my-grocery-card {
  padding-right: 6vh;
  padding-left: 6vh;
}

.custom-my-grocery-img {
  width: 5%;
}

.custom-div-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Custom Modals */

.modal-header {
  /* background-color: var(--primary-color) !important; */
  color: #ffffff;
  padding: 0.2rem 15px !important;
  align-items: center !important;
  color: black;
  border-bottom: none !important;
}

.modal-header > button > span {
  color: #ffffff;
}

/* Input priority */

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 1 !important;
}

/* Animation saltello*/

.verticalListaSpesa,
.vertical {
  animation: vertical_207 5.44s ease infinite;
  transform-origin: 50% 50%;
  animation-delay: -3.94s;
}

/* Animation saltello solo se in customProduct*/

.customProduct .vertical {
  animation: fadeIn 3s infinite alternate;
  transform-origin: 0% 0%;
}

.fadeIn_interact {
  animation: fadeInInter 1.2s linear;
  transform-origin: 0% 0%;
  animation-delay: 0s;
}

@keyframes fadeInInter {
  0% {
    background-color: rgba(220, 35, 17, 0);
  }

  50% {
    background-color: var(--hover-color);
  }

  100% {
    background-color: rgba(220, 35, 17, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes vertical_207 {
  0% {
    transform: translate(0, -3px);
  }

  1.47059% {
    transform: translate(0, 3px);
  }

  2.94118% {
    transform: translate(0, -3px);
  }

  4.41176% {
    transform: translate(0, 3px);
  }

  5.88235% {
    transform: translate(0, -3px);
  }

  7.35294% {
    transform: translate(0, 3px);
  }

  8.08824% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

/* Share */

.custom-share-container {
  margin-left: -5px !important;
}

.custom-share-container * {
  margin-right: 5px;
  cursor: pointer;
}

.modal_share_custom {
  max-width: 399px !important;
  width: 95% !important;
}
.modal_share_custom .custom-share-container {
  gap: 15px;
  justify-content: space-between;
}
.modal_share_custom .custom-share-container * {
  margin: 0;
}
.modal_share_custom .modal-header {
  padding: 20px 32px 24px !important;
}
.modal_share_custom .modal-body {
  padding: 0 32px 32px !important;
}
.modal_share_custom .modal-content {
  /* height: 150px; */
  display: flex;
  justify-content: center;
  border-radius: 8px !important;
}

/*Sidebar*/

/* .custom-sidebar-header>img {
    width: 100% !important;
} */

#sidebar {
  z-index: 5;
}

.slider-list:focus,
.slider-slide:focus,
.slider-list,
.slider-slide,
.slider-slide div,
.slider-slide div:focus,
.slider-frame,
.slider {
  outline: none !important;
  border: none;
  outline: none;
}

.headerBack {
  margin-bottom: 15px;
}

.mobilePadding5 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.slider-list {
  /*min-height: calc(100vh - 163px);
    min-height: inherit;
    min-height: calc(var(--vh, 1vh) * 100 - 163px); */
}

.slider-list li {
  /*  position: relative !important; 
  height: auto !important;*/
}

#closeIcon {
  display: none;
}

.carousel-pagination-button {
  /* padding: 0.25rem 0.75rem !important; */
  /* font-size: 1rem !important; */
  /* line-height: 100% !important; */
}

/* Mobile */

@media screen and (max-width: 991px) {
  /* .right-sidebar {
    display: none;
  } */

  .width20 li {
    width: 100% !important;
  }

  #closeIcon {
    display: block;
  }

  #closeIcon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  #prodottoNonPresente {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }

  .mobilePadding5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .iconTopName {
    color: #009ee0;
    font-size: 11px;
    line-height: 13px;
  }

  .headerBack .mini-drawer-menu-icon {
    height: 48px;
    margin: 0px !important;
  }

  .checkMobileClassImg {
    height: auto;
    /* width: auto !important; */
    max-width: 100%;
    max-height: calc(var(--vh, 1vh) * 100 - 113px) !important;
  }

  .custom-search-li {
    display: none !important;
  }

  .custom-search-bar-wrapper .input-group-text {
    cursor: pointer;
    background-color: var(--secondary-color) !important;
    border: 1px solid var(--secondary-color) !important;
    color: #ffffff !important;
  }

  .headerBack {
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    padding-right: 0px !important;
    padding: 0 !important;
  }

  .headerBack .top-header-icon {
    margin-top: 0px !important;
    width: 29px !important;
    height: 29px !important;
    background: transparent !important;
    box-shadow: none !important;
  }

  #mobile-volantino i {
    color: #009ee0 !important;
  }

  .headerBack .top-header-icon i {
    font-size: 30px !important;
  }

  .route-height {
    /* margin-right: 0 !important; */
  }

  .mobile-grocery-list {
    display: block !important;

    padding-top: 4px;
    height: 48px;
    margin-right: 0 !important;
    text-align: center;
  }

  .mobile-grocery-list div {
    border-radius: 0 !important;
  }

  /* .headerBack .top-header-icon {
    margin: auto;
  } */

  #mobile-search-hidden {
    display: none !important;
  }
}

@media screen and (max-width: 600px) {
  .reduceMobile {
    font-size: 15px;
    text-overflow: clip;
    overflow: hidden;
  }

  .custom-sidebar-header > img {
    width: 80% !important;
  }

  .custom-container {
    background-color: rgb(250, 250, 250);
    padding: 5%;
  }

  .filtered-products .marker {
    display: block !important;
  }

  /*icone marker prodotto in categorie*/
  @media screen and (max-width: 600px) {
    .marker .icon-marker {
      /* margin-right: 5px; */
      width: 30px;
    }
  }

  /*icone marker sul prodotto*/
  @media screen and (max-width: 600px) {
    .icon-marker {
      width: 35px;
      margin-right: 15px !important;
      margin-left: 0px !important;
    }

    .markers-container-no-margin .icon-marker {
      margin-right: 5px !important;
      margin-left: 0px !important;
    }

    .customProduct .marker .icon-marker {
      /* margin-right: 5px !important; */
      width: 22px !important;
      margin-right: 0 !important;
      justify-content: center;
    }
    .customProduct .marker .icon-marker svg {
      width: 19px;
    }

    .marker .icon-marker {
      margin-right: 5px !important;
      width: 23px;
    }
  }

  .floating-grocery-button {
    display: none !important;
  }

  /*Modal Full Screen*/
  .modal-dialog {
    margin: auto !important;
  }

  .modal-content {
    border: none !important;
    border-radius: inherit !important;
  }

  @media (max-width: 991px) {
    .modal-content {
      /* height: 100%; */
    }
  }

  /* .custom-popover {
    transform: unset !important;
    margin-top: 0px !important;
    border: unset !important;
    border-radius: unset !important;
    max-width: 100% !important;
    width: 100%;
    height: 100% !important;
  } */

  #react-select-2-listbox {
    z-index: 99999;
  }

  /* 
  @media screen and (max-height: 340px) {
    .custom-scrollbars {
      height: 35vh !important;
    }
  }

  @media screen and (max-height: 430px) and (min-height: 341px) {
    .custom-scrollbars {
      height: 40vh !important;
    }
  }

  @media screen and (max-height: 550px) and (min-height: 431px) {
    .custom-scrollbars {
      height: calc(100vh - 323px) !important;
      height: calc(var(--vh, 1vh) * 100 - 201px) !important;
    }
  }

  @media screen and (min-height: 551px) and (max-height: 700px) {
    .custom-scrollbars {
      height: calc(100vh - 323px) !important;
      height: calc(var(--vh, 1vh) * 100 - 161px) !important;
    }
  }

  @media screen and (min-height: 701px) {
    .custom-scrollbars {
      height: calc(100vh - 273px) !important;
      height: calc(var(--vh, 1vh) * 100 - 161px) !important;
    }
  } */

  /* Gestione scrollbar in grocery list da mobile */
  /*Modal plus*/

  .custom-grocery-list-carousel {
    display: none !important;
  }

  .custom-grocery-product-info-container {
    min-height: 0px;
  }

  /* Scroll to top */
  /* .scroll-up-btn {
    bottom: 50px !important;
    right: 55px !important;
    font-size: 2em;
    z-index: 2;
  } */

  .icon-marker {
    margin-right: 10px;
  }
}

@media screen and (max-height: 600px) {
  .custom-my-grocery-scrollbar {
    height: 50vh !important;
  }
}

@media screen and (max-height: 700px) and (min-height: 601px) {
  .custom-my-grocery-scrollbar {
    height: 50vh !important;
  }
}

@media screen and (max-height: 800px) and (min-height: 701px) {
  .custom-my-grocery-scrollbar {
    height: 50vh !important;
  }
}

@media screen and (min-height: 801px) {
  .custom-my-grocery-scrollbar {
    height: 50vh !important;
  }
}

@media screen and (min-height: 1001px) {
  .custom-my-grocery-scrollbar {
    height: 50vh !important;
  }
}

@media screen and (max-width: 1500px) {
  .custom-my-grocery-img {
    width: 70px !important;
    height: 70px !important;
  }
}

.scroll-up-btn {
  position: fixed;
  bottom: 5px;
  right: 55px;
  cursor: pointer;
  color: var(--primary-color);
  z-index: 2;
}

@media screen and (max-width: 1500px) {
  .scroll-up-btn {
    right: 65px !important;
    /* right: 20px !important; */
  }
}

.css-1nmdiq5-menu {
  z-index: 99999 !important;
}

@media screen and (min-width: 993px) {
  .wrap_text_p {
    max-height: 300px !important;
  }

  #scroller {
    padding-top: 15px;
  }

  .styleScrollableCategories .css-1nmdiq5-menu {
    position: sticky !important;
  }
}

@media screen and (max-width: 992px) {
  .scroll-up-btn {
    right: 20px !important;
    /* right: 20px !important; */
  }
}

.filter-scroll-up {
  position: fixed;
  right: 10px;
  cursor: pointer;
  color: white;
  z-index: 2;
}

/* ricerca in index */

li.alice-carousel__stage-item.__active {
  width: calc(100% / 8) !important;
}

.alice-carousel__wrapper {
  height: 30vh !important;
}

.search-carousel-close-icon {
  color: var(--secondary-color);
}

.search-carousel-icon {
  cursor: pointer;
}

.search-carousel-icon-wrapper {
  height: calc(30vh - 60%);
}

.search-carousel-icons-container {
  height: 30vh;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1250px) {
  .alice-carousel__wrapper {
    height: 20vh !important;
  }

  .search-carousel-icon-wrapper {
    height: calc(20vh - 60%);
  }

  .search-carousel-icons-container {
    height: 20vh;
    display: flex;
    flex-direction: column;
  }
}

/* ricerca in index */

/** PAGE TITLE STYLE */

.page-title {
  background: var(--primary-color);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 20px 0px,
    var(--primary-color) 0px 13px 24px -11px;
}

.page-title-color {
  color: var(--primary-color);
}

.client-logo-header-mygrocerylist {
  width: 80px;
  border-radius: 50%;
}

.app-logo-header-mygrocerylist {
  width: 70px;
  border-radius: 50%;
}

@media screen and (max-width: 600px) {
  .client-logo-header-mygrocerylist {
    width: 50px;
    border-radius: 50%;
  }

  .app-logo-header-mygrocerylist {
    width: 40px;
    border-radius: 50%;
  }
}

/** PAGE TITLE STYLE */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .top-header-icon {
    background: var(--primary-color) !important;
  }

  .icon-marker {
    height: 25px;
  }
}

.change-product-quantity-icon {
  color: var(--primary-color);
}

.related_products_container .alice-carousel__wrapper {
  height: auto !important;
}

.related_products_container {
  overflow-y: hidden;
}

.related_products_container li.alice-carousel__stage-item.__active {
  width: calc(100% / 5) !important;
}

.mobileCarouselAlice li.alice-carousel__stage-item.__active {
  width: calc(100% / 5) !important;
}

/* hide scrollbar in Chrome and Safari */

#scrollable-div::-webkit-scrollbar {
  width: 0 !important;
}

/* hide scrollbar in Firefox */

#scrollable-div {
  scrollbar-width: none;
}

/* hide scrollbar in IE */

#scrollable-div {
  -ms-overflow-style: none;
}

.custom-page-title {
  font-size: 2vw !important;
}

@media screen and (max-width: 600px) {
  .custom-page-title {
    font-size: 4vw !important;
  }
}

.modal-page-video-container {
  background: #777;
}

@media screen and (max-width: 767px) {
  .modal-page-video-container {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    height: 0;
    padding-bottom: 56.25%;
    height: 75%;
  }

  #md .slider {
    margin-top: 35%;
  }
}

@media screen and (min-width: 768px) {
  .modal-page-video-container {
    position: absolute;
    bottom: 0;
    /* left: calc(50% - 500px);
    right: calc(50% - 500px); */
    left: 0;
    right: 0;
    /* height: 0; */
    /* padding-bottom: 56.25%; */
    padding-bottom: 33.75%;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
}

.react-page-player {
  position: absolute;
  top: 0;
  left: 0;
}

.close-page-video-button {
  cursor: pointer;
  background: #000;
  opacity: 0.5;
  color: #fff;
  border: 0;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .close-page-video-button {
    font-size: 14px;
  }
}

.audio-page-video-button {
  cursor: pointer;
  background: #000;
  opacity: 0.5;
  color: #fff;
  border: 0;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.audio-page-video-button-bottom-left {
  cursor: pointer;
  background: #000;
  opacity: 0.5;
  color: #fff;
  border: 0;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.audio-page-video-button-top-left {
  cursor: pointer;
  background: #000;
  opacity: 0.5;
  color: #fff;
  border: 0;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .audio-page-video-button {
    font-size: 14px;
  }

  .audio-page-video-button span {
    display: none;
  }

  .audio-page-video-button-top-left {
    font-size: 14px;
  }

  .audio-page-video-button-bottom-left {
    font-size: 14px;
  }

  .audio-page-video-button-bottom-left span {
    display: none;
  }
}

.wait-page-video-button {
  background: #000;
  opacity: 0.5;
  color: #fff;
  border: 0;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .wait-page-video-button {
    width: 100%;
    border: 0;
    padding: 5px 20px;
    font-size: 14px;
    right: 0;
    left: 0;
    bottom: 0;
    top: auto;
    z-index: 2;
    text-align: center;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .onlyShopping {
    height: 70px;
    font-size: 15px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 1700px) {
  .onlyShopping {
    height: 45px;
    font-size: 15px;
  }
}

/*Sottotitolo Volantino Interattivo*/

.subtitle-mobile {
  font-size: 14px;
  margin-left: 33px;
  margin-top: 5px;
}

.subtitle-desktop {
  color: var(--primary-color) !important;
  font-size: 18px;
  margin-left: 0px;
  margin-top: 0px;
}

#videoIper {
  display: flex;
  /* background-color: var(--primary-color) !important; */
  background-color: white !important;
}

.logo_full {
  width: 100% !important;
}

.logo_small {
  width: 70% !important;
}

.btn-live {
  background-color: #c3cedd !important;
  color: #5a7294 !important;
  padding: 0.1rem !important;
}

#zoom-btns .carousel-pagination-button {
  /* background-color: var(--tertiary-color) !important;
  border-color: var(--tertiary-color) !important; */
  /* padding: 10px !important; */
  /* font-size: 20px !important; */
  cursor: pointer;
  display: flex;
}
.ZoomSlider {
  width: 70px !important;
  height: 5px !important;
}
.ZoomSlider div:first-child {
  background-color: #365da1;
}
.ZoomSlider .css-iaai0d-Slider,
.ZoomSlider div:last-child > div {
  width: 8px;
  height: 8px;
}
.pageinnerBox {
  width: 100% !important;
  height: 5px !important;
}
.pageinnerBox .ZoomSlider {
  width: 100% !important;
}
.pageinnerBox .ZoomSlider .css-iaai0d-Slider,
.pageinnerBox .ZoomSlider div:last-child > div {
  width: 12px;
  height: 12px;
}
.signlePageBox {
  width: 100%;
  display: flex;
}
.signlePageBox .ZoomSlider {
  width: 100% !important;
}
.signlePageBox .ZoomSlider .css-iaai0d-Slider,
.signlePageBox .ZoomSlider div:last-child > div {
  width: 12px;
  height: 12px;
}
#zoom-btns .carousel-pagination-button i {
  font-size: 10px;
}
#zoom-btns .carousel-pagination-button:hover {
  /* background-color: var(--secondary-color) !important; */
}

#zoom-btns-mobile {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 150px;
  margin: auto;
  left: 0;
  right: 0;
}

#zoom-btns-mobile .carousel-pagination-button {
  font-size: 20px !important;
  margin: 0 5px;
}

.centred-carousel {
  position: absolute;
  top: 50%;
  width: 100%;
  -ms-transform: translateY(-60%);
  transform: translateY(-60%);
}

#carousel .shadow {
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15) !important;
}

.select-variety {
  /* width: calc(100% - 50px); */
  width: 100%;
}

.select-variety > div {
  border-color: transparent !important;
  border-radius: 8px !important;
  border: 0.5px solid;
  background: #f5f7fa;
}

.slide-shadow::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: inset 20px 0px 21px -10px rgba(255, 255, 255, 0.1),
    inset 13px 0px 21px -10px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  z-index: 20;
  transform-origin: 0% 50%;
  transition: transform 0.6s ease;
}

#mobile-volantino #mobile_index_icon {
  color: #0bbbef !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  /* filter: invert(1) */
  width: 30px !important;
  height: 30px !important;
  background-color: var(--primary-color) !important;
  border-radius: 4px !important;
}

.carousel img {
  width: unset !important;
}

.discount-rate {
  background: #ffdd00;
  border-radius: 100px;
  padding: 2px 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 37px;
  color: black !important;
  margin: 0 0 0 15px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  vertical-align: top;
}

/* .right-sidebar-wrapper {
  height: unset !important;
  position: absolute !important;
  bottom: 0 !important;
} */

#mobile-volantino .btn,
.link-container .btn,
.link-container a {
  color: white;
  font-weight: 900 !important;
  padding: 0;
}

#mobile-volantino .btn,
.link-container .btn:focus {
  box-shadow: none;
}

.link-container a:hover {
  text-decoration: none;
}

#logo-full {
  transition-duration: 0.4s;
}

.link-title {
  transition-duration: 5s;
}

#input-page {
  /* background-color: var(--primary-color); */
  background-color: rgba(255, 255, 255, 0.3);
  /* background-color: #ababab; */
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
  width: 60px;
  line-height: normal;
  text-align: right;
}
#input-page::placeholder {
  color: #000;
}
#input-page::-webkit-outer-spin-button,
#input-page::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#input-page[type="number"] {
  -moz-appearance: textfield;
}
#input-page:focus-visible {
  outline: none;
}
.SliderStyle {
  height: 5px !important;
}
.SliderStyle div:first-child {
  background-color: #365da1;
}
.SliderStyle .css-iaai0d-Slider,
.SliderStyle div:last-child > div {
  width: 12px;
  height: 12px;
}

.absolute_placeholder::placeholder,
.absolute_placeholder::-webkit-input-placeholder {
  color: #0008;
  font-size: 1rem;
  position: absolute !important;
  right: 0;
  top: 1px;
}

.awssld {
  height: 100%;
}

.awssld__content {
  background-color: #f7f7f8 !important;
  /* align-items: unset !important; */
}

.awssld__content > div:first-child {
  height: 100%;
}

#custom_grocery_button svg {
  width: 40px;
}

#removeAllBtn:hover {
  background-color: unset !important;
}

#removeAllBtn:focus {
  box-shadow: none;
}

.H_not_adsense {
  height: calc(100% - var(--h-pager));
  width: 100%;
}

@media (max-width: 991px) {
  .H_not_adsense {
    height: calc(100% - var(--h-pager));
  }
}

.H_adsense {
  height: calc(100% - (var(--h-adsense) + var(--h-pager)));
  width: 100%;
}

@media (max-width: 991px) {
  .H_adsense {
    height: calc(100% - (var(--h-adsense) + var(--h-pager)));
  }
}

.H_adsense_mobile_detected {
  height: calc(100% - var(--h-adsense));
}

.H_not_adsense_mobile_detected {
  height: 100%;
}

#control-height {
  height: 100vh;
  width: 0;
  position: absolute;
}

/* #scrollbars > div:nth-child(1){
  margin-bottom: 0 !important; 
} */

.custom-col-1 {
  flex: 0 0 8.333333% !important;
  max-width: 36px !important;
}

.custom-col-12 {
  flex: 0 0 100% !important;
  max-width: calc(100% - 72px) !important;
}

.background_color {
  background-color: var(--background-color);
}

@media (max-width: 575px) {
  .custom-col-12 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.bCkPrJ {
  border: 1px solid white;
}

.jNsYLy .sideBack {
  overflow: visible !important;
}

.nav {
  display: block !important;
}

/* a.nav-link.main-list,
div.nav-link.main-list {
  cursor: pointer;
  text-align: center;
  padding: 10px 0;
  text-align: center;
  margin: 0;
} */

.fade_p {
  opacity: 0;
  visibility: hidden;
}

.sidebar-wrapper p {
  color: #0008;
}

.sidebar-wrapper > ul.nav > li.li_sidebar:hover {
  /* background-color: transparent;
  opacity: 0.95;
  animation: open 0.2s ease-out 0s 1 normal;
  width: 210px; */
  /* border: 1px solid var(--primary-color); */
  /* border-top-right-radius: 30px;
  border-bottom-right-radius: 30px; */
}

.sidebar-wrapper > ul.nav > li.li_sidebar:hover p {
  color: var(--primary-color);
}

/* .sidebar-wrapper > ul.nav > li:hover {
  background-color: var(--hover-sidebar-color);
} */

/* .sidebar-wrapper > ul.nav > li:hover svg path,
.sidebar-wrapper > ul.nav > li:hover svg circle,
.sidebar-wrapper > ul.nav > li:hover svg rect {
  stroke: var(--primary-color);
} */

/* .sidebar-wrapper > ul.nav > li:hover p {
  color: white;
} */

/* .sidebar-wrapper > ul.nav > li.li_sidebar:hover .fade_p {
  opacity: 1;
  visibility: visible;
  animation: change_opacity 0.4s ease-out 0s 1 normal;
  text-transform: uppercase;
  margin-left: 1rem;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  width: fit-content;
  position: absolute;
  left: 50px;
  padding: 0 10px;
  border-radius: 10px;
  color: white;
  line-height: 28px !important;
  top: 18px;
} */

/* .sidebar-wrapper>ul.nav>li.li_sidebar:hover>a>i,
.sidebar-wrapper>ul.nav>li.li_sidebar:hover>div>i {
  animation: jump 0.5s ease-out normal;
}

.sidebar-wrapper>ul.nav>li.li_sidebar:hover>a>svg,
.sidebar-wrapper>ul.nav>li.li_sidebar:hover>div>svg {
  animation: jump 0.5s ease-out normal;
} */

.sidebar-header {
  padding: 8px 3px 3px 3px !important;
}

.logo-mini {
  margin-left: 5px !important;
}

.logo-mini-right {
  margin-left: 0 !important;
}

.jNsYLy .sidebar-wrapper .active {
  background-color: transparent !important;
}

.jNsYLy .sidebar-wrapper .active i,
.jNsYLy .sidebar-wrapper .active p {
  color: var(--secondary-color);
}

.li_sidebar_hover {
  background-color: var(--hover-sidebar-color);
}

@keyframes open {
  0% {
    width: 0;
  }

  100% {
    width: 225px !important;
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  10% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  20% {
    -webkit-transform: translateY(-0px);
    transform: translateY(-0px);
  }

  30% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }

  40% {
    -webkit-transform: translateY(-0px);
    transform: translateY(-0px);
  }

  70% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes change_opacity {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.pointer:hover {
  cursor: pointer;
}

.sc-bwzfXH {
  border-bottom: 1px solid var(--background-color);
}

.custom_header_container_fluid {
  margin-right: 55px !important;
}

.headerBack {
  background: none !important;
}

.custom-scrollbars > :first-child {
  padding-bottom: 90px;
}

.shadow-right {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 98%,
    rgba(2, 0, 36, 0.2) 100%
  );
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

.shadow-left {
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 0) 98%,
    rgba(2, 0, 36, 0.2) 100%
  );
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
}

.modal .modal-dialog {
  transform: translate3d(0, 100vh, 0);
}

.modal .modal-dialog {
  transform: translate3d(0, 0, 0);
}

.bordo {
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
  overflow: hidden;
}

.carousel-root {
  display: flex;

  justify-content: center;
}

.roe-card-body span {
  font-family: var(--) !important;
}

.custom-padding {
  padding: 2.5px 10px 2.5px 10px !important;
}

.custom-padding .ptb-15 {
  padding-bottom: 2.5px !important;
  padding-top: 2.5px !important;
}

.slider > .slide.selected > img,
.slider > .slide > img {
  max-width: 80% !important;
}

.c-light.custom-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  font-weight: 600 !important;
}

.c-light.custom-light:hover {
  background-color: var(--secondary-color) !important;
  color: white !important;
  font-weight: 600 !important;
}

.dueiwp .roe-card-header.product {
  padding: 15px 15px !important;
}

.dueiwp .roe-card-body.product,
#scroller .roe-card-body {
  padding: 0 5px 5px !important;
}

.slick-prev:before,
.slick-next:before {
  color: var(--primary-color) !important;
}

.dueiwp .roe-card-header {
  padding: 5px 5px !important;
}

.text-truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-first {
  z-index: 9 !important;
}

.iconSide {
  transition: all 0.5s ease;
}

.rtf--mb {
  background-color: white !important;
}

.rtf--mb svg path {
  stroke: var(--primary-color) !important;
}

.rtf--ab {
  background-color: white !important;
}

.rtf--ab svg {
  width: 80%;
}

.paused {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.popover-inner {
  display: flex;
  flex-direction: column;
}
.custom-popover .popover-inner {
  height: 100%;
}
.popover.bs-popover-auto {
  transform: translate(90px, 0) !important;
  bottom: 100px !important;
  top: 20% !important;
  box-shadow: 0 0 10px #ccc;
}
.popover .arrow.popover-arrow {
  display: none;
}
.iperBimboMarker.marker img.icon-marker {
  width: 30px !important;
}
.custom-popover .popover-body {
  height: calc(100% - 70px) !important;
}
.iperBimboMarker.marker[type="play"] svg {
  width: 40px !important;
  animation: vertical_207 5.44s ease infinite;
  transform-origin: 50% 50%;
  animation-delay: -3.94s;
}

/* PAGE ANIMATION */
.zoomInAnimation {
  animation: zoomIn 3s normal linear;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.5);
  }

  50% {
    transform: scale(2);
  }

  75% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.zoomOutAnimation {
  animation: zoomOut 3s normal linear;
}

@keyframes zoomOut {
  0% {
    transform: scale(2);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.slideInAnimation {
  animation: slideIn 1.3s normal linear;
}

@keyframes slideIn {
  0% {
    translate: -500px;
  }

  25% {
    tranlate: -375px;
  }

  50% {
    translate: -250px;
  }

  75% {
    translate: -125px;
  }

  100% {
    translate: 0px;
  }
}

.slideOutAnimation {
  animation: slideOut 1.3s normal linear;
}

@keyframes slideOut {
  0% {
    translate: 500px;
  }

  25% {
    tranlate: 375px;
  }

  50% {
    translate: 250px;
  }

  75% {
    translate: 125px;
  }

  100% {
    translate: 0px;
  }
}

.customProduct:has(#videoIper) {
  background-color: white !important;
}

.video-button-store > a:first-of-type {
  margin-top: 10px;
}

@media (max-width: 992px) {
  .custom-flex-dir {
    flex-direction: column-reverse;
  }
}

.custom_pt-3 {
  padding-top: 1rem;
}

.login_button {
  color: var(--secondary-color);
  font-weight: bold;
  cursor: pointer;
}

.login_button:hover {
  color: var(--primary-color);
}

.user-avatar {
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  border-radius: 25px;
  cursor: pointer;
}

.initials {
  font-size: 15px;
}

.custom_inter {
  position: relative;
}

.input-group-prepend {
  display: none !important;
}

.iconStyleModal {
  margin-left: 0;
  border: 1px solid var(--primary-color);
  border-radius: 100%;
  width: 20px !important;
  height: 20px;
  padding: 3px;
}

.close-modal-btn:hover {
  cursor: pointer;
}

.dueiwp .roe-card-body.product,
#scroller .roe-card-body.product_card_cat {
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.iconL,
.iconR {
  cursor: "pointer";
}
/* 
.modal {
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 15px !important;
} */

.modal .modal-dialog {
  height: 50%;
  width: 50%;
}

.modal .main-modal.modal-dialog {
  width: 90%;
  max-width: none;
  height: 100%;
  margin: 0 auto;
}

.modal .main-modal .modal-content {
  /* height: 90vh; */
  border: 0;
  border-radius: 15px !important;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 25px 0 17px;
}

.no-visual-search {
  pointer-events: none;
  z-index: -1;
}

@media (min-width: 992px) {
  .modal .main-modal.modal-dialog {
    width: 80%;
  }

  .modal .main-modal .modal-content {
    height: auto;
    overflow-y: auto;
  }

  .overflow-lg-scroll {
    overflow-y: scroll;
  }
}

@media (min-width: 1280px) {
  .modal .main-modal.modal-dialog {
    width: 1200px;
  }
  .modal.fade .modal-dialog.custom-bs-modal.has-related-products {
    width: 1200px;
  }
  /* .modal .main-modal.modal-dialog.normal-modal {
    width: 1200px;
  } */
}
@media (min-width: 1500px) {
  .modal.fade .modal-dialog.custom-bs-modal.has-related-products {
    width: 1460px;
  }
}
.modal .modal-body {
  overflow-y: auto;
  overflow-x: hidden;
}

/* SLICK */

select {
  font-family: var(--font-client) !important;
}

.css-1fdsijx-ValueContainer {
  padding-left: 1rem !important;
}

.css-1dimb5e-singleValue::before {
  content: attr(data-before);
  position: absolute;
  font-family: var(--font-client);
  color: black;
  opacity: 0.5;
  left: 10px;
  top: 5.15px;
  font-size: 0.85rem;
}

/* .css-1dimb5e-singleValue {
  padding-left: 3.1rem !important;
} */

.product_card_container {
  background-color: #fff;
}

.product_card_container {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) and (max-width: 1200px) {
  .product_card_container:nth-child(2n) {
    border-right: none;
  }
}

@media (min-width: 1200px) {
  .product_card_container:nth-child(4n) {
    border-right: none;
  }
}
@media (max-width: 1199px) {
  #input-page {
    /* width: 100%;
    max-width: 120px;
    text-align: right;
    font-size: 0.9rem !important;
    border-bottom: 0.5px solid #0008;
    padding-left: 0px !important;
    text-align: center !important;
    padding-bottom: 4px !important; */
  }
}
@media (max-width: 991px) {
  .product_card_container {
    border-right: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .route-height {
    /* padding-bottom: 60px !important; */
    /* padding-top: 120px; */
    /* height: calc(100vh - 50px) !important; */
  }
}

.sidebar-wrapper .li_sidebar .active svg rect,
.sidebar-wrapper .li_sidebar .active svg circle,
.sidebar-wrapper .li_sidebar .active svg path {
  stroke: var(--primary-color) !important;
}

.cls-1 {
  stroke: #0009;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px !important;
}

.cls-1:not(.cls-custom) {
  fill: none !important;
}

.cls-custom {
  stroke-width: 0.005px !important;
  opacity: 0.7;
  fill: #0009;
}

.cls-2 {
  fill: none !important;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px !important;
}

.cls-3 {
  fill: none !important;
  stroke: var(--color-interattivo-primary);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px !important;
}

/* .li_sidebar,
.li_sidebar a,
.li_sidebar div {
  height: 60px !important;
}

.li_sidebar a,
.li_sidebar div {
  margin: 0 !important;
} */

.li_sidebar {
  /* display: flex; */
  align-items: center;
  margin-bottom: 0.5rem;
}

.fade_p {
  line-height: 40px !important;
}
.carousel-footer {
  /* position: fixed;
  z-index: 99;
  bottom: 15px; */
  max-width: 965px !important;
  position: relative;
  margin: auto;
  /* height: 49px; */
  padding: 12px 39px 12px 35px !important;
}

.carousel-footer path.cls-1 {
  stroke-width: 5px !important;
}

.search_box_container {
  overflow: auto;
  scrollbar-width: thin;
  /* Larghezza sottile */
  scrollbar-color: gray lightgray;
  /* Colore scrollbar */
  z-index: 99;
}

.search_box_container::-webkit-scrollbar {
  width: 8px;
  /* Larghezza */
}

.search_box_container::-webkit-scrollbar-thumb {
  background-color: #0008;
  /* Colore del pollice */
}

.search_box_container::-webkit-scrollbar-track {
  background-color: white;
  /* Colore del track */
}

.custom_width_marker {
  /* width: 40px; */
}

.mtop-3 {
  margin-top: 1rem !important;
}

.mtop-5 {
  margin-top: 3rem !important;
}
.relative {
  position: relative;
}
.carousel_img_modal {
  height: 60vh;
  object-fit: contain;
  width: 100%;
}
.carousel-footer-inner {
  /* display: grid;
  grid-template-columns: 135px auto 210px; */
  display: flex;
  justify-content: space-between;
  gap: 15px;
  /* padding: 0 10px; */
  align-items: center;
}
.pageinnerBox {
  /* justify-content: center; */
  gap: 35px;
  width: 100%;
}
#pagers {
  position: fixed;
  bottom: 4px;
  left: auto;
  width: calc(100% - 78px);
  transform: inherit;
  z-index: 9;
  right: 0;
}
.leaflet {
  height: 100%;
  padding-bottom: 50px;
}
@media screen and (max-width: 1299px) {
  #pagers {
    padding: 0 40px 0 20px;
  }
}
@media screen and (max-width: 991px) {
  #pagers {
    justify-content: start !important;
    padding-left: 15px !important;
  }

  #pagers .carousel-footer {
    max-width: calc(100% - 160px) !important;
    margin: 0 !important;
  }
  .rightSidebarWidth #pagers .carousel-footer {
    /* max-width: calc(100% - 285px) !important; */
    margin-left: 15px;
  }
  .pageinnerBox .carousel-pagination-button {
    padding: 0 5px !important;
  }
}

@media screen and (max-width: 767px) {
  #pagers .carousel-footer {
    max-width: calc(100% - 130px) !important;
    margin: 0 !important;
    padding: 10px 16px !important;
  }
  .rightSidebarWidth #pagers .carousel-footer {
    /* max-width: calc(100% - 160px) !important; */
    margin-left: 15px;
  }
}

@media screen and (max-width: 1199px) {
  .carousel_img_modal {
    max-height: 300px !important;
  }
}
/* SLICK */
.slick-slider,
.slick-list,
.slick-track,
.slick-slide > div {
   height: 100% !important; 
}

.slick-list img{
  height:100%;
}

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-25 {
    width: 25% !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }
  .video_file.video-responsive video {
    height: calc(80vh - 230px) !important;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .h-md-25 {
    height: 25% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .w-lg-50 {
    width: 50% !important;
  }

  .w-lg-25 {
    width: 25% !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }

  .w-xl-75 {
    width: 75% !important;
  }

  .w-xl-50 {
    width: 50% !important;
  }

  .w-xl-25 {
    width: 25% !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }
}

.btn_dynamic_cat {
  max-height: 38px;
}

#react-select-2-listbox {
  overflow: hidden;
}

.mainContentCookie {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  left: 50%; /* Posiziona il div rispetto al centro orizzontale dello schermo */
  transform: translateX(-50%); /* Centra orizzontalmente il div */
  top: auto;
  z-index: 10;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out; /* Transition applicata a tutte le proprietà */
  opacity: 1;
  width: 100%; /* Larghezza iniziale al 100% */
}

.mainContentCookie.centered {
  transform: translate(
    -50%,
    -50%
  ); /* Centra sia verticalmente che orizzontalmente il div */
  top: 50%; /* Posiziona il div rispetto al centro verticale dello schermo */
  bottom: auto;
  align-items: center;
  justify-content: center;

  width: 50%; /* Larghezza quando è centrato al 50% */
}

.mainContentCookie.centered .globalContainerCookie {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.globalContainerCookie {
  box-shadow: 0 30px 70px rgba(0, 0, 0, 0.3);
  height: auto;
  /* max-height: 70vh; */
  /* max-width: 100vw;
  min-width: 100vw; */
  padding: 24px;
  transition-property: transform, opacity, top;
  width: 100vw;
  z-index: 10;
  background-color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 30px;
  display: flex;
  /* overflow-y: scroll;
  overflow-x: hidden; */
}
.logoCookieContainer {
  flex: 1;
}

.contentCookie {
  flex: 5;
}

.buttonCookieContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 2;
}

.switchContainer {
  display: flex;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  margin-top: 5px;
}

.switchGlobalContainer {
  display: flex;
}

.buttonCookie {
  border-radius: 5px;
  /* padding:10px; */
  width: 70%;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 20px;
  letter-spacing: 1px;
  cursor: pointer;
}

.acceptCookieButton {
  background-color: #1032cf;
  border-color: #1032cf;
  color: #ffffff;
}

.rifiutaCookieButton {
  background-color: transparent;
  color: #000;
  border: 2px solid #1032cf;
  margin-top: 15px;
}

.eye_icon {
  position: absolute;
  right: 10px;
  top: 18px;
  opacity: 0.5;
  cursor: pointer;
}
@media (max-width: 992px) {
  .categories-static-inner .product-item-block-wrap {
    padding: 5px !important;
  }
}
@media (max-width: 992px) {
  .mainContentCookie.centered {
    width: 90%; /* Larghezza quando è centrato al 50% */
  }

  .globalContainerCookie {
    max-height: 99vh;
    overflow-y: auto;
  }
}

.header-popover-width {
  .popover {
    min-width: 500px;
    @media (max-width: 576px) {
      min-width: 100vw;
    }
  }
}
@media (max-width: 768px) {
}
@media (max-width: 576px) {
  /* .modal-dialog.main-modal .xs-h-auto {
    height: auto !important;
  } */
  .mpl-5 {
    padding-left: 5px !important;
  }
}

/* Slick arrows*/
.slick-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.slick-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.slick-prev {
  left: 0;
  z-index: 1;
}

.slick-next {
  right: 0;
}

/* Slick Dots */
.slick-dots {
  border: none;
  bottom: -50px;
}
.slick-dots li {
  width: auto;
}

.slick-dots li button:before {
  content: none;
}

.slick-dots li button {
  width: 10px;
  height: 10px;
  background: #d9d9d9;
  border-radius: 100%;
  padding: 0;
}

.slick-dots li.slick-active button {
  width: 30px;
  border-radius: 40px;
  background-color: var(--primary-color);
}

.select-variety .css-1u9des2-indicatorSeparator {
  width: 0;
}

.select-variety .css-1dimb5e-singleValue {
  color: #033ca7;
  font-size: 14px;
}

.select-variety .css-1xc3v61-indicatorContainer {
  color: rgba(var(--primary-blue-rgb), 1);
  font-size: 7px;
}

.select-variety .css-1xc3v61-indicatorContainer svg {
  width: 16px;
}

.discounted-price {
  font-size: 20px;
  font-family: "Rubik";
  font-style: italic;
  text-decoration: line-through;
  font-weight: 400;
}

.select-quantity-wrap {
  display: flex;
  align-items: center;
  border: 1px solid #58b94b;
  border-radius: 6px;
  overflow: hidden;
  width: 100% !important;
  justify-content: space-between;
}

.select-quantity-wrap .increase-quantity,
.select-quantity-wrap .decrease-quantity {
  min-width: 40px;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: center;
  background: #58b94b;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.select-quantity-wrap .show-quantity {
  min-width: 90px;
  padding: 0 10px;
  text-align: center;
}

/* New UI BH */
/*30-04-2024*/
.main-modal.custom-bs-modal .modal-header-cs {
  height: 68px;
}
.main-modal.custom-bs-modal .modal-footer-cs {
  /* height: 62px; */
  /* min-height: 50px; */
  /* padding-top: 15px;
  padding-bottom: 15px; */
}
.main-modal.custom-bs-modal .modal-footer-cs .modal-footer {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  height: auto !important;
  padding-bottom: 2px !important;
}
.main-modal.custom-bs-modal.has-no-footer .modal-footer-cs {
  display: none !important;
}
.main-modal.custom-bs-modal .modal-body-cs {
  overflow-y: auto;
  height: calc(80vh - 132px);
}
.main-modal.has-related-products .modal-body-cs {
  height: calc(80vh - 78px);
}
.product-modal .modal-footer-cs {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 9;
}
.product-modal .modal-content-main-wrap {
  height: 100%;
}
.product-modal .modal-content-main-wrap .modal-left-content {
  width: 100%;
}
.product-modal.has-related-products
  .modal-content-main-wrap
  .modal-left-content {
  padding-bottom: 62px;
}
.product-modal.has-related-products
  .modal-content-main-wrap
  .modal-left-content {
  width: calc(100% - 230px);
  border-right: 1px solid #d7d7d7;
}
.product-modal.has-related-products
  .modal-content-main-wrap
  .modal-right-content {
  width: 230px;
  height: calc(80vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
.product-modal .modal-header-wrap {
  border-bottom: 1px solid #d7d7d7;
  padding: 21px 0 20px;
}
.product-modal .modal-header-wrap .social-icon {
  line-height: normal;
}
.product-modal .modal-header-cs {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
.product-modal.has-related-products .modal-right-content {
  padding-left: 25px;
  padding-right: 10px;
}
.product-modal .related-product-card {
  padding: 30px 21px 18px 22px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 9px;
}
.product-modal .modal-content-main-wrap .modal-product-sidebar > h5 {
  margin-bottom: 18px;
  font-size: 16px;
  color: #0d0040;
}
.product-modal .modal-footer-wrap-cs {
  width: 100%;
}

.product-modal.has-related-products .modal-footer-cs {
  width: calc(100% - 260px);
  padding-left: 17px;
}
.product-modal.has-related-products .modal-footer-wrap-cs {
  padding-right: 20px;
}
.product-modal .product-details-content {
  padding-right: 70px;
  padding-left: 35px;
}
.modal .product-modal.main-modal .modal-content {
  overflow-y: hidden;
}

.related-product-card .product-title {
  font-size: 16px;
}

.related-product-card .product-title-2 {
  font-size: 12px;
}

.related-product-card .product-price {
  font-size: 20px;
  margin-top: 8px;
  font-weight: 700;
}
.bodyHeight {
  /* overflow-y: scroll; */
  overflow-y: hidden;
  height: calc(100vh - 260px);
}
.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.tag-block-item {
  opacity: 0.5;
  cursor: pointer;
}
.tag-block-item.selected,
.product-price-modal .tag-block-item,
.tag-price-modal-block .tag-block-item {
  opacity: 1;
}
.search_bar {
  margin-top: 46px;
}
a.theme-brand {
  display: block;
  text-align: center;
  margin-bottom: 40px;
}
a.theme-brand img {
  width: auto !important;
  max-width: 100%;
  max-height: 84px;
  height: auto;
  margin: auto;
}
.custom-search-bar-wrapper {
  position: relative;
}
.custom-search-bar-wrapper input {
  padding: 10px 20px;
  border: 1px solid #365da1;
  border-radius: 4px !important;
}
.custom-search-bar-wrapper input:focus {
  box-shadow: unset;
}
.custom-search-bar-wrapper svg {
  width: 30px;
  position: absolute;
  top: 50% !important;
  transform: translate(0, -50%) !important;
  right: 15px !important;
}
.search-result {
  box-shadow: 0px 0px 11px #ccc;
  border-radius: 8px;
  margin-bottom: 16px;
  background: #fff;
  padding: 32px 27px 28px 16px;
  text-align: center;
}
.search-result-box {
  /* height: calc(100vh - 250px); */
  max-height: calc(100vh - 145px);
  overflow: auto;
  padding: 25px 10px;
  scrollbar-width: none;
}
.search-result-box::-webkit-scrollbar {
  display: none !important;
}
.search-result-box::-webkit-scrollbar-track {
  background: transparent !important;
}
.search-result-box::-webkit-scrollbar-thumb {
  background: transparent !important;
}
.search-result-box::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
}
.result-title {
  text-align: left;
  font-size: 18px;
  line-height: normal;
  color: #0d0040;
  margin-top: 25px;
}
.search-result p {
  color: #0d0040;
  font-size: 14px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.productpageslisting {
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 30px;
}
.container-arrow {
  width: 100%;
}
.rightSidebarWidth .container-arrow {
  width: calc(100% - 300px);
}
.carousel-footer-right {
  display: flex;
  align-items: center;
  gap: 25px;
}
/* .rightSidebarWidth .carousel-footer-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
} */
/* .rightSidebarWidth #pagers,
.searchbarOpenWidth #pagers {
  height: auto !important;
} */
.awssld__content
  .row.w-100.background_color
  .d-flex.justify-content-end.col-6.p-0:empty {
  display: none !important;
}
.overflowhidden {
  overflow: hidden !important;
}
.product-details-content.overflowPage {
  height: calc(80vh - 175px);
  overflow-y: auto;
  overflow-x: hidden;
}
.video-responsive {
  padding: 15px 45px 0 58px;
}
/* .bodyContentSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */
.searchbarOpenWidth #pagers {
  width: calc(100% - 428px);
}
@media (max-width: 1699px) {
  .searchbarOpenWidth #pagers .carousel-footer {
    margin-right: 180px;
  }
}
@media (max-width: 1599px) {
  .searchbarOpenWidth #pagers .carousel-footer {
    max-width: calc(100% - 200px) !important;
  }
}
@media (max-width: 1399px) {
  .carousel-footer {
    max-width: calc(100% - 330px) !important;
    /* padding: 8.5px 20px !important; */
    /* margin-bottom: 0 !important; */
  }
  .rightSidebarWidth .carousel-footer {
    /* max-width: calc(100% - 285px) !important;
    margin-left: 15px; */
    margin-left: 15px;
  }
  .bodyContentSection {
    display: block;
  }
  .video-responsive {
    padding: 0;
  }
}
@media (max-width: 1280px) {
  .select-quantity-wrap .show-quantity {
    min-width: 70px;
    padding: 0 5px;
  }
  .select-quantity-wrap .increase-quantity,
  .select-quantity-wrap .decrease-quantity {
    min-width: 30px;
    height: 32px;
  }
  .modal .main-modal.modal-dialog {
    max-width: 1200px;
    width: 75%;
  }
  .modal.fade .modal-dialog.custom-bs-modal.has-related-products {
    width: 95%;
  }
  .productpageslisting {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding-bottom: 35px;
  }
  .leaflet {
    padding-bottom: 38px;
  }
  .carousel-footer-right {
    gap: 15px;
  }
  .carousel-footer-inner {
    grid-template-columns: 135px auto 193px;
  }
  #pagers {
    /* bottom: 55px; */
    /* left: 15px;
    right: 15px;
    transform: unset; */
  }
  /* .inpublish-icon-container {
    bottom: 55px;
    right: 25px;
  } */
  /* .store-result-container {
    max-width: 315px !important;
  }
  .store-result {
    padding: 15px;
  } */
}
@media (max-width: 1199px) {
  .search_box_container {
    width: 200px !important;
  }
  .store-result-container {
    width: 100% !important;
  }
  /* a.theme-brand img {
    height: auto;
    width: 100%;
  } */
  .custom-search-bar-wrapper {
    padding: 0;
  }
  .custom-search-bar-wrapper input {
    padding: 8px 25px 8px 8px;
    font-size: 13px;
  }
  .custom-search-bar-wrapper svg {
    width: 20px;
    right: 9px !important;
  }
  .searchbarOpenWidth {
    width: calc(100% - 275px) !important;
  }
  .listingBox img {
    width: 100%;
  }
  .rightSidebarWidth .container-arrow {
    /* width: calc(100% - 310px); */
    width: 100%;
  }
  .product-details-content.overflowPage {
    height: calc(80vh - 205px);
  }
  .searchbarOpenWidth #pagers .carousel-footer {
    margin-left: 10px;
    max-width: calc(100% - 170px) !important;
  }
  .searchbarOpenWidth #pagers {
    width: calc(100% - 278px);
    /* height: auto !important; */
  }
  .searchbarOpenWidth #pagers .carousel-footer-inner {
    /* flex-wrap: wrap; */
  }
  /* .search_box_container {
    width: 200px !important;
    height: calc(100vh - 50px) !important;
  } */
}
@media (max-width: 991px) {
  .rightSidebarWidth {
    margin-right: 230px !important;
  }
  #zoom-btns .carousel-pagination-button {
    /* width: 30px;
    margin: 0 !important;
    padding: 0 !important;
    display: flex; */
  }
  .carousel-footer {
    /* max-width: calc(100% - 210px) !important; */
    /* margin-left: 0; */
  }
  .container-arrow {
    /* width: calc(100% - 250px);
    padding-left: 20px;
    height: 45px;
    min-width: 200px; */
  }
  .productCostomPosition img.img-fluid {
    max-height: calc(100vh - 150px) !important;
  }
  .pageinnerBox {
    gap: 15px;
  }
  .search_box_container {
    height: calc(100% - 60px) !important;
  }
  .searchbarOpenWidth #pagers {
    width: calc(100% - 78px);
    height: 50px !important;
  }
}
@media (max-width: 768px) {
  /* #pagers {
    left: 15px;
    transform: unset;
  } */
  #zoom-btns {
    /* gap: 0 !important; */
  }
  .ZoomSlider {
    width: 25px !important;
  }
  .carousel-footer-inner {
    grid-template-columns: 112px auto 115px;
  }
  .carousel-footer-right {
    gap: 10px;
  }
  .rightSidebarWidth .carousel-footer {
    /* max-width: 100% !important; */
    /* padding: 5px 5px 5px 0 !important; */
  }
  .carousel-footer-right svg {
    width: 18px;
    height: 18px;
  }
  .carousel-pagination-button span {
    font-size: 14px !important;
  }
  #input-page {
    margin: 0;
    padding: 0;
    /* font-size: 14px; */
  }
  .carousel-footer {
    max-width: calc(100% - 130px) !important;
  }
  .searchbarOpenWidth #pagers .carousel-footer {
    max-width: calc(100% - 130px) !important;
  }
}
@media (max-width: 767px) {
  .ytp-mobile-a11y-hidden-seek-button {
    display: none !important;
  }
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-left-content {
    width: calc(100% - 150px);
  }
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-right-content {
    width: 150px;
    height: calc(100vh - 230px);
  }
  .product-modal.has-related-products .modal-footer-cs {
    width: calc(100% - 180px);
  }
  .carousel-pagination-button {
    /* padding: 7px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .shareBtn svg,
  .shareContent svg {
    width: 18px !important;
    height: 18px;
  }
  .shareContent {
    font-size: 12px;
  }
  .popover.bs-popover-auto {
    min-width: 470px;
  }
  .carousel-footer-inner {
    display: flex;
    justify-content: space-between;
    /* gap: 0 !important; */
  }
  #pagers {
    padding: 0;
  }
  .carousel-pagination-button input {
    width: 50px !important;
    padding: 0 !important;
  }
  .carousel-pagination-button input::placeholder {
    font-size: 14px;
  }
  .pageinnerBox .carousel-pagination-button {
    width: auto !important;
    padding: 0 3px !important;
  }
  .right-sidebar .right-sidebar-toggle {
    padding: 8px;
    left: -25px;
  }
  #zoom-btns .carousel-pagination-button svg {
    width: 25px !important;
  }
  .inpublish-icon-container {
    margin: 0;
    max-width: 100px;
  }
  .inpublish-icon-container svg {
    width: 100%;
  }
}
@media (max-width: 575px) {
  #pagers {
    display: none !important;
  }
  .popover.bs-popover-auto {
    min-width: 100%;
  }
  .route-height {
    /* height: calc(100vh - 70px) !important;
    padding-top: 120px;
    padding-bottom: 70px; */
  }
  .scroll-up-btn {
    bottom: 80px;
    background: #fff;
    border-radius: 100%;
  }
  .route-height {
    margin-right: 0 !important;
    padding: 0 !important;
    overflow: auto;
    height: calc(100vh - 192px) !important;
  }
  /*  */
  #scrollbars {
    height: -webkit-fill-available !important;
    /* position: relative; */
    /* z-index: 9; */
    position: unset !important;
  }
  #scrollbars > div {
    overflow: unset !important;
  }
  .main-panel {
    position: fixed;
    /* height: calc(100vh - 190px); */
  }
  .leaflet {
    padding-bottom: 50px;
  }
  .mainBody {
    height: 100vh !important;
    overflow: auto;
    padding-top: 121px;
    padding-bottom: 70px;
  }

  .bodyStyle {
    height: 100vh;
  }
  .mainBody .bodyHeight {
    height: calc(100vh - 206px) !important;
    /* height: 100% !important; */
    padding-bottom: 80px;
  }
  .modal.fade .modal-dialog.custom-bs-modal.has-related-products {
    width: 100%;
  }
  .complete-modal-body.relative {
    padding: 0 !important;
    overflow: auto !important;
    /* height: calc(85vh - 140px) !important; */
    height: calc(80vh - 70px) !important;
  }
  .productpageslisting {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding-bottom: 80px;
    height: 100%;
    overflow: auto;
  }
  .main-modal.custom-bs-modal .modal-header-cs {
    height: auto;
  }
  .main-modal.custom-bs-modal .modal-body-cs {
    /* padding-top: 60px; */
  }
  .modal .modal-body .complete-modal-body {
    padding-top: 10px !important;
    /* height: calc(80vh - 20px) !important; */
  }
  .modal .modal-body .complete-modal-body.no-space {
    /* padding-top: 0 !important; */
  }
  .mobile-header.shadowNone {
    box-shadow: unset !important;
  }
  .productCostomPosition img.img-fluid {
    max-height: unset !important;
  }
  .mainBody.hideHead {
    padding-top: 72px;
    padding-bottom: 50px;
  }
  .mainBody.hideHead .route-height {
    height: calc(100vh - 122px) !important;
  }
  .mainBody.hideHead .bodyHeight {
    height: calc(100vh - 122px) !important;
  }
}

@media screen and (max-width: 1399px) and (orientation: landscape) {
  .carousel_img_modal {
    height: auto;
  }
  .awssld__wrapper {
    height: auto;
  }
  /* #pagers {
    left: 20px;
    transform: unset;
    /* bottom: 45px; */

  .carousel-footer {
    /* width: 750px !important;
    margin: auto !important; */
  }
  /* .awssld__content > div:first-child .productCostomPosition {
    padding: 60px 0 !important;
    height: calc(100vh - 60px);
  } */
  /* .awssld__content > div:first-child img.img-fluid {
    height: calc(100vh - 250px) !important;
  } */
  .awssld__content .carouselLoader .imgLoader {
    height: auto !important;
  }
  .searchbarOpenWidth #pagers {
    /* width: calc(100% - 378px); */
    height: auto !important;
  }
  .searchbarOpenWidth #pagers .carousel-footer {
    margin-right: 160px;
  }
  .searchbarOpenWidth #pagers .carousel-footer {
    margin-left: 0;
    max-width: calc(100% - 155px) !important;
  }
  .searchbarOpenWidth #pagers .carousel-footer-inner {
    /* flex-wrap: wrap; */
  }
}
@media screen and (max-width: 1199px) and (orientation: landscape) {
  /* .container-arrow {
    width: calc(100% - 330px);
    padding-left: 20px;
    height: 45px;
  } */
  .carousel-footer {
    /* max-width: 600px !important; */
  }
  .route-height {
    padding-top: 0;
  }
  .leaflet {
    padding-bottom: 0;
  }
  #pagers {
    /* bottom: 50px; */
    /* width: auto; */
  }

  .carousel-footer-inner {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between; */
  }
  .carousel-pagination-button {
    padding: 0.25rem !important ;
  }
  .filtered-products,
  .productpageslisting {
    padding-bottom: 50px !important;
  }
  .categories-static-inner {
    margin-bottom: 45px !important;
  }
  .withoutsidebarpanel {
    width: 100% !important;
  }
}
@media screen and (max-width: 991px) and (orientation: landscape) {
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-left-content {
    height: calc(80vh - 100px);
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 60px;
  }
  /* #pagers {
    bottom: 10px;
  } */
  .main-modal.custom-bs-modal .modal-header-cs {
    height: auto;
  }
  .modal .product-modal.main-modal .modal-content {
    height: 80vh;
  }
  .video-responsive.video_iframe {
    height: 100% !important;
  }
  .modal .modal-body {
    padding: 10px 0 !important;
  }
  .modal_share_custom .modal-body {
    padding: 0 32px 32px !important;
  }
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-right-content {
    height: calc(80vh - 80px);
  }
  .product-modal.has-related-products .modal-content-main-wrap {
    height: 100%;
    overflow: hidden;
  }
  .product-modal .modal-content-main-wrap .product-details-content {
    height: calc(80vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
  }
  /* .product-modal .product-details-content {
    height: calc(80vh - 160px);
  } */
  .mobilelandscape {
    /* height: 100% !important; */
    height: calc(80vh - 160px) !important;
  }
  .product-modal .product-details-content .add-cart-modal-btn {
    height: 34px;
  }
  /* cart model start */
  .custom-popover .custom-popover-header {
    padding: 10px 0;
  }
  .custom-popover
    .custom-popover-header
    .header-left-content
    .forward-icon
    .shareButton {
    height: 30px;
  }
  .popover.bs-popover-auto {
    top: 15px !important;
    bottom: 15px !important;
    height: calc(100vh - 30px);
  }
  .custom-popover .popover-body {
    overflow: auto;
    height: auto !important;
    padding: 10px 10px 10px 0px;
  }
  .custom-popover .cart-card-wrapper {
    height: auto;
    overflow: visible;
  }
  .custom-popover .cart-price-info-wrapper {
    margin: 10px 0;
  }
  /* cart model end */
  .sidebar-wrapper .sidebarMainMenu ul {
    height: auto;
    max-height: unset;
    overflow: unset;
    margin: 0;
    border: none;
  }
  .sidebar-wrapper .main-list {
    padding-bottom: 0 !important;
    gap: 5px;
  }
  .sidebar .sidebar-wrapper {
    gap: 0;
    overflow: auto;
  }
  .sidebar-wrapper .sidebarMainMenu {
    height: auto;
  }
  .sidebar .sidebar-wrapper .user-profile-wrap {
    margin-top: 10px;
  }
  .inpublish-icon-container {
    /* bottom: 68px; */
  }
  .carousel-footer {
    /* max-width: calc(100% - 190px) !important;
    margin-left: 20px; */
  }
  .shadow.carousel-footer.progressbarfooter {
    max-width: 500px !important;
  }
  .product-category-block {
    height: calc(100vh - 190px) !important;
  }
  /* .awssld__content > div:first-child img.img-fluid {
    height: calc(100vh - 170px) !important;
  } */
  #scrollbars {
    height: 100vh !important;
  }
  .right-sidebar {
    /* top: 10px;
    transform: translateX(100%) translateY(0); */
  }
  .right-sidebar.sidebar-open {
    /* transform: translateX(0) translateY(0); */
  }
  .awssld__content > div:first-child img.img-fluid {
    max-height: calc(-168px + 100vh) !important;
  }
  .route-height {
    padding-bottom: 60px !important;
  }
  .searchbarOpenWidth #pagers {
    /* width: calc(100% - 278px); */
    width: calc(100% - 78px);
    height: 50px !important;
  }
  .search-result-box {
    max-height: calc(100vh - 205px);
    height: auto;
  }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  /* #pagers {
    left: 10px;
    width: calc(100% - 50px) !important;
  } */
  .awssld__content > div:first-child .productCostomPosition {
    /* padding: 40px 0 !important; */
  }
  .carousel-footer {
    /* width: 100% !important;
    margin-right: 25px !important; */
    /* max-width: calc(100% - 130px) !important; */
    margin-left: 0;
  }
  .carousel-footer-inner #zoom-btns {
    align-items: center;
  }
  #zoom-btns .carousel-pagination-button {
    /* width: 25px;
    height: 20px; */
    padding: 0 !important;
  }
  .pageinnerBox .carousel-pagination-button {
    height: auto !important;
  }
  .product-modal .modal-header-wrap {
    padding-top: 0 !important;
  }
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-right-content,
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-left-content {
    height: calc(80vh - 60px);
  }
  .product-modal .modal-content-main-wrap .product-details-content {
    height: calc(80vh - 140px);
  }
  .video-responsive.video_iframe {
    height: calc(80vh - 110px) !important;
  }
  .rightSidebarWidth .carousel-footer-inner {
    /* display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between; */
  }
  .searchbarOpenWidth #pagers .carousel-footer {
    max-width: calc(100% - 130px) !important;
  }
  .search_box_container {
    /* height: 100vh !important; */
  }
}
@media screen and (max-device-width: 1399px) and (orientation: portrait) {
  .searchbarOpenWidth #pagers {
    /* width: calc(100% - 428px); */
  }
}
@media screen and (max-device-width: 1199px) and (orientation: portrait) {
  /* .carousel-footer {
    max-width: inherit !important;
  }
  .carousel-footer-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  #pagers {
    width: calc(100% - 120px);
  } */
}
@media screen and (max-device-width: 991px) and (orientation: portrait) {
  /* #pagers {
    left: calc(50% - 20px);
    width: calc(100% - 70px);
    bottom: 20px;
  } */
  .awssld__content > div:first-child .productCostomPosition {
    /* justify-content: flex-start !important; */
  }
  .awssld__content > div:first-child .productCostomPosition img {
    max-height: calc(100vh - 235px) !important;
  }
}
@media screen and (max-device-width: 575px) {
  /* .main-modal.custom-bs-modal .modal-body-cs {
    padding-top: 30px;
  } */
  /* .awssld__content > div:first-child .productCostomPosition {
    height: calc(100vh - 250px) !important;
    overflow: auto !important;
    justify-content: flex-start !important;
  }
  .awssld__content > div:first-child .productCostomPosition .noAnimation {
    padding-bottom: 60px;
  }
  .awssld__content > div:first-child .productCostomPosition img {
    max-height: unset !important;
    width: 100%;
    height: 100%;
  } */
  /* .awssld__content > div:first-child .productCostomPosition {
    justify-content: flex-start !important;
  }
  .awssld__content > div:first-child .productCostomPosition img {
    max-height: calc(100vh - 270px) !important;
  } */
}
@media screen and (max-device-width: 575px) and (orientation: portrait) {
  .noAnimation {
    text-align: center;
    background: transparent;
    margin: auto;
  }
  /* .awssld__content > div:first-child .productCostomPosition {
    display: block !important;
    overflow: auto;
    border-radius: 0 !important;
  } */
  .awssld__container .awssld__box .productCostomPosition .noAnimation {
    width: calc(100% - 30px);
    padding-bottom: 0 !important;
  }
  .awssld__content > div:first-child .productCostomPosition img {
    border-radius: 0.7rem;
  }
  .page-scroll > div:first-child {
    margin-bottom: 0 !important;
  }
  .route-height {
    /* height: 100% !important; */
  }
}

.my-grocery-list-header-desktop {
  height: 7vh;
}

.my-grocery-list-header-desktop img {
  max-height: 98%;
}

.my-grocery-list-header-mobile {
  height: 15vh;
}

.my-grocery-list-header-mobile .action-row {
  background-color: #ffffff;
  height: 7vh;
}

.my-grocery-list-header-mobile .client-logo-row {
  height: 8vh;
}

.my-grocery-list-header-mobile .client-logo-row img {
  max-height: 98%;
}

.btn-back-leaflet {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.my-grocery-list-container {
  border-radius: 12px;
}

.my-grocery-list-header {
  border-bottom: 1px solid #e5e5e5;
}

.mobile-top-bar .left-content svg {
  width: 40px;
}
#root
  > div
  > div.mobile-footer.d-block.d-sm-none
  > ul
  > li:nth-child(1)
  > button
  > svg {
  height: 50px;
}
@media (max-width: 2560px) and (orientation: landscape) {
  .slick-image-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-left-content {
    padding: 0px !important;
  }

  .slick-image-left {
    justify-content: flex-start;
  }
}
.custom-srollbars {
  overflow-y: scroll;
  height: 55vh;
}

@media (max-aspect-ratio: 19.5/9) {
  .custom-srollbars {
    height: 60vh;
  }
}

.btn-blank {
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}

.my-grocery-element-qta {
  border-radius: 8px;
  border: 1px solid #dadbe1;
  text-align: center;
  font-weight: bold;
}

.my-grocery-element-field1 {
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.my-grocery-element-fields {
  font-size: 1rem;
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.my-grocery-element-price {
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 389px) {
  .my-grocery-element-field1 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .my-grocery-element-fields {
    font-size: 1.2rem;
  }
  .my-grocery-element-price {
    font-size: 2rem;
  }

  .custom-srollbars {
    overflow-y: scroll;
    height: 55vh;
  }
}

.my-grocery-list-price-p {
  text-align: right;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
}

@media (min-width: 768px) {
  .my-grocery-list-price-p {
    border-top: 1px solid #d7d7d7;
  }
  .my-grocery-list-container {
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
  }
  .my-grocery-element-field1 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .my-grocery-element-fields {
    font-size: 1.2rem;
  }
  .my-grocery-element-price {
    font-size: 2rem;
  }

  .custom-srollbars {
    overflow-y: scroll;
    height: 55vh;
  }

  .sticky-image-desktop {
    position: sticky;
    top: 0;
  }
}

@media (min-width: 1600px) {
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.666667% !important;
  }
}

@media only screen and (max-width: 575px) {
  /* .mobile-footer {
    z-index: 1111;
  } */
}
