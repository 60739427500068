.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 3;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  overflow-x: hidden;
  background-position: center center;

  .sidebar-wrapper {
    position: relative;
    height: 100dvh;
    z-index: 4;
    padding: 12px 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // overflow-y: hidden !important;
    gap: 16px;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-track {
      background: #e5e5e5;
    }
    &::-webkit-scrollbar-thumb {
      background: #365da1;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .logo img {
      width: 40px;
      margin: 0 auto;
    }
    .nav {
      .sidebar-nav-item {
        .nav-link {
          // padding: 12px 10px;
          padding: 9.5px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          gap: 10px;
          transition: all 300ms ease-in-out;
          border-radius: 8px;
          // color: var(--primary-dark);
          color: #151515 !important;
          cursor: pointer;
          .nav-item-label {
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            display: inline-block;
          }
          &:hover,
          &.active {
            background-color: rgba(var(--primary-blue-rgb), 0.05);
            color: var(--secondary-color) !important;
            .nav-item-label {
              font-weight: 600;
            }
          }
        }
      }
    }
    .user-profile-wrap {
      flex-direction: column;
      gap: 6px;
      align-items: center;
      justify-content: center;
      .user-name {
        color: red;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
      }
      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 4px;
        margin: 0 auto;
      }
    }
  }

  .nav {
    // margin-top: 20px;
    li {
      width: 100%;
      .card {
        .card-body {
          a {
            color: #ffffff;
            opacity: 0.86;
            border-radius: 4px;
            margin: 5px 10px 0;
          }
        }
      }
      > a {
        color: var(--primary-dark);
        // opacity: 0.86;
        // border-radius: 4px;
        // margin: 5px 10px 0;
      }
    }
    .caret {
      top: 24px;
      position: absolute;
      right: 15px;
    }

    .span-shortname {
      font-size: 12px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }

    i {
      font-size: 18px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }

    // svg {
    //   font-size: 18px;
    //   float: left;
    //   margin-right: 15px;
    //   line-height: 30px;
    //   width: 45px;
    //   text-align: center;
    //   margin-left: 0.35rem;
    // }
  }
}

.sidebar,
body > .navbar-collapse {
  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

// .main-panel {
//   background: rgba(203, 203, 210, 0.15);
//   position: relative;
//   float: right;
//   width: calc(100% - 260px);
//   height: 100vh;
// }

.main-panel,
.navbar-fixed,
.sidebar,
.sidebar-wrapper,
.logo-wrapper {
  -webkit-transition-property: top, bottom, width;
  -o-transition-property: top, bottom, width;
  transition-property: top, bottom, width;
  -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
  -o-transition-duration: 0.2s, 0.2s, 0.35s;
  transition-duration: 0.2s, 0.2s, 0.35s;
  -webkit-transition-timing-function: linear, linear, ease;
  -o-transition-timing-function: linear, linear, ease;
  transition-timing-function: linear, linear, ease;
  -webkit-overflow-scrolling: touch;
}

.right-sidebar {
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 1;
  background-color: #fff;
  transition: all 300ms ease-in-out;
  transform: translateX(100%) translateY(-50%);
  width: 225px;
  height: 85vh;
  border-top-left-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  // overflow-y: auto;
  // overflow-x: visible;
  @media screen and (max-width: 991px) and (orientation: landscape) {
    height: 70vh;
    top: 46%;
  }
  &.sidebar-open {
    transform: translateX(0) translateY(-50%);
  }
  @media (max-width: 991px) {
    // transform: translateX(100%) translateY(0);
    // top: 0;
    // &.sidebar-open {
    //   transform: translateX(0) translateY(0);
    // }
  }
  @media (max-width: 767px) {
    width: 207px;
    // height: 80vh;
  }
  .right-sidebar-toggle {
    position: absolute;
    bottom: 0;
    padding: 15px;
    z-index: 9;
    left: -30px;
    background: #fff;
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    box-shadow: -5px 0px 13px -6px rgba(0, 0, 0, 0.1);
  }
  .right-sidebar-header {
    height: 100%;
  }
  .right-sidebar-navlink-wrap {
    padding: 20px 20px 20px 30px;
    height: 100%;
    overflow: auto;
    @media (max-width: 767px) {
      padding: 15px;
    }
    .right-sidebar-navlink {
      button {
        width: 100%;
        background: rgba(var(--primary-blue-rgb), 0.05);
        color: #0d0040;
        font-size: 0.9rem;
        font-weight: 500;
        padding: 12px 10px;
        border-radius: 8px;
        border: 1px solid transparent;
        margin-bottom: 16px;
        min-height: 55px;
        transition: all 300ms ease-in-out;
        &.active,
        &:hover {
          background: rgba(var(--primary-blue-rgb), 1);
          color: #ffffff;
        }
        @media (max-width: 767px) {
          padding: 10px;
          font-size: 14px;
          margin-bottom: 10px;
          min-height: unset;
        }
      }
      .navlink-content-wrap {
        display: flex;
        gap: 10px;
        align-items: center;
        .navlink-content-page {
          width: 30px;
        }
        .navlink-content-name {
          width: calc(100% - 30px);
          text-align: left;
          font-size: 14px;
        }
      }
    }
  }
}
