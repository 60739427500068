.storemap_block {
  display: flex;
  height: 100vh;
  padding-left: 1px;
}
.store_search_box_container {
  width: 100%;
  max-width: 375px;
  height: 100%;
  box-shadow: 0 0 6px #ccc;
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
  box-shadow: rgb(204, 204, 204) 0px 0px 6px;
  background-color: rgb(255, 255, 255);
}
.search_bar {
  margin: 10px 0;
}
.store-thumbnail {
  height: 62px;
  width: 66px;
  object-fit: contain;
}
.store-address {
  font-size: 12px;
}
.map-container {
  flex: 1;
  height: 100%;
  width: 100%;
}
.store-result-box {
  height: calc(100% - 180px);
  overflow: auto;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
/* .store_search_box_container .search-result-box::-webkit-scrollbar {
  display: none !important;
} */
/* .store-result-box::-webkit-scrollbar {
  display: none !important;
}
.store-result-box::-webkit-scrollbar-track {
  background: transparent !important;
}
.store-result-box::-webkit-scrollbar-thumb {
  background: transparent !important;
}
.store-result-box::-webkit-scrollbar-thumb:hover {
  background: transparent !important;
} */
.store-result {
  /* border-top: 1px solid var(--dark-gray, #e5e5e5); */
  background: #fff;
  cursor: pointer;
  display: flex;
  /* width: 374px; */
  height: auto;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  border-bottom: 1px solid var(--dark-gray, #e5e5e5);
}
.store-result:hover {
  /* background-color: #f1f1f1; */
}
.store-result .store-Collapse {
  width: 100%;
}
.store-header {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
  padding-right: 40px;
  width: 100%;
}
.store-name {
  font-size: 16px;
  font-weight: bold;
}
.store-info {
  font-size: 14px;
}
.accordion-toggle {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
}
.showDetail .store-header .accordion-toggle svg {
  width: 28px;
  height: 28px;
}
.store-header .accordion-toggle svg {
  fill: #000 !important;
  stroke: #000 !important;
}
.store-hours,
.store-services,
.store-offers {
  margin-top: 10px;
}
.store-hours ul {
  padding-left: 20px;
}
.leaflet-popup {
  width: 354px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.volantino-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.buttons-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.buttons-group .btn-secondary {
  line-height: 21px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.store-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 70px;
}
.details {
  color: var(--Copy, #0d0040);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.store-name {
  color: var(--Copy, #0d0040);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: block;
}
.store-address,
.store-code {
  color: var(--Copy, #0d0040);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  display: block;
}
.popup-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.map-container .leaflet-popup-content-wrapper {
  width: 354px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.05);
}
.map-container .leaflet-popup-content {
  padding: 24px;
  margin: 0;
  width: 100% !important;
}
button.btn.btn-white {
  border: 1px solid #365da1 !important;
  color: #0d0040;
  line-height: 21px;
  font-size: 16px;
}
.leaflet-popup-tip-container {
  left: unset;
  bottom: -20px;
  right: 10px;
}
.leaflet-popup-close-button {
  display: none;
}
.leaflet-fade-anim .leaflet-popup {
  left: unset !important;
  right: -30px;
  bottom: 0 !important;
}
.store-result-container .search_bar {
  margin: 10px 0px 0;
  padding: 0 20px 30px;
  border-bottom: 1px solid #e5e5e5;
}
.store-open {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 24px;
}
.store-naviga {
  margin-bottom: 28px;
}
.store-naviga button {
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.store-repart-box {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.store-reparti,
.store-servizi,
.store-volantini {
  padding-bottom: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid #e5e5e5;
}
.store-repart-box__inner {
  width: 24px;
  height: 24px;
}
.store-repart-box__inner img {
  width: 100%;
}
.storebar-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #0d0040;
  margin-bottom: 16px;
}
.store-servizi-box {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  text-align: center;
}
.store-servizi-box__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(33.33% - 16px);
}
.store-servizi-box__inner img {
  width: 24px;
  height: 24px;
  margin: auto;
  margin-bottom: 8px;
}
.store-servizi-box__inner span {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.store-aperture ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.store-aperture ul li {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #0d0040;
}
.store-aperture ul li:last-child {
  margin: 0;
}
.store-aperture ul li.selected {
  color: #365da1;
  font-weight: 700;
}
.loadingText {
  display: block;
  width: 100%;
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: gray;
}
.showmenu {
  display: none;
}

.store-volantini-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.store-volantini-box__inner {
  display: flex;
  align-items: center;
  gap: 16px;
}
.store-volantini-box__inner img {
  height: 80px;
  width: 61px;
}
.store-volantini-content h3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #0d0040;
}
.store-volantini-content p {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: #0d0040;
  margin-bottom: 5px;
}
.store-volantini-content .store-volantini-btn {
  font-size: 12px;
  font-style: normal;
  color: #0d0040;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}
.store-volantini-content .store-volantini-btn svg {
  padding-left: 4px;
}
.store_search_box_container ~ div .react-swipeable-view-container {
  display: none !important;
}
.no-stores-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1rem;
  color: black;
  text-align: center;
  padding: 20px;
}
@media only screen and (max-width: 575px) {
  .store_search_box_container ~ div .react-swipeable-view-container {
    display: flex !important;
  }
  .search_box_desktop {
    display: none;
  }
  .showmenu {
    background-color: white;
    bottom: 138px !important;
    visibility: visible !important;
    height: 88px !important;
    width: 100% !important;
    overflow-y: hidden !important;
    display: block;
    border-bottom: solid 1px #e5e5e5;
    transition: 0.3s;
  }
  .showmenu[aria-hidden="false"] {
    background-color: #fff;
    height: calc(100vh - 290px) !important;
    bottom: 50px !important;
  }
  .store_search_box_container {
    max-width: 100%;
    box-shadow: unset;
    border-top: solid 1px #e5e5e5;
    padding: 0;
    overflow: visible;
    height: calc(100% - 75px);
  }
  .store-result {
    padding: 32px 24px;
  }
  .mobile_searchbar {
    padding: 0px 24px;
  }
  .custom-search-bar-wrapper input {
    padding: 14px 25px 14px 8px;
    font-size: 13px;
    line-height: 16px;
    border: 1px solid #003da6;
  }
  .custom-search-bar-wrapper input::placeholder {
    color: #bfc9de;
  }
  .custom-search-bar-wrapper.input-group svg path {
    fill: #0d0040;
  }
  .custom-search-bar-wrapper input:focus {
    border: 1px solid #003da6;
  }
  .scrollbaar {
    width: 48px;
    height: 4px;
    background-color: #838384;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 12px;
  }
  .search_bar {
    margin: 12px 0;
  }
  .store-thumbnail {
    height: 40px;
    width: 43px;
    object-fit: contain;
  }
  .store-result-box {
    height: auto;
    /* max-height: 100% !important; */
    height: calc(100vh - 429px);
  }
  .storemap_block.showDetail .mobile_searchbar {
    display: none;
  }
  .storemap_block .ReactSwipeableBottomSheet--open,
  .storemap_block .ReactSwipeableBottomSheet--closed {
    height: auto !important;
    max-height: unset !important;
    overflow: visible !important;
  }
  .storemap_block .ReactSwipeableBottomSheet--open {
    height: calc(100vh - 352px) !important;
  }
  .storemap_block.showList.showDetail .ReactSwipeableBottomSheet--open {
    height: calc(100vh - 252px) !important;
    overflow: auto !important;
    bottom: unset !important;
    top: 120px !important;
  }
  .react-swipeable-view-container {
    background-color: #fff;
  }
  .storemap_block.showList .react-swipeable-view-container {
    top: 88px;
    position: relative;
  }
  .storemap_block.showList.showDetail .store-result-box {
    height: calc(100vh - 257px) !important;
    /* padding-bottom: 100px !important; */
  }
  .offCanvas {
    transition: transform 0.3s ease-in-out;
  }
  .leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
    left: -177px !important;
  }
  .leaflet-popup-tip-container {
    left: 50%;
  }
}
