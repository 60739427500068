@media (max-width: 1599px) {
  .product-modal .product-details-content {
    padding-left: 10px;
    padding-right: 10px;
  }
  .main-modal .product-price-block {
    flex-direction: column-reverse;
    gap: 10px;
    align-items: flex-start;
    margin: 0;
    padding-top: 4px;
  }
}
@media (max-width: 1366px) {
  .searchbarOpenWidth
    .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker {
    width: 20px;
    height: 20px;
    flex-basis: 20px;
  }
  .right-sidebar.sidebar-open
    ~ .main-panel
    .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker {
    width: 20px;
    height: 20px;
    flex-basis: 20px;
  }
  .bordo {
    margin-top: -50px;
  }
}
@media (max-width: 1200px) {
  .product-modal .product-details-content {
    padding-left: 0 !important;
    /* padding-right: 0 !important; */
  }
  .product-item-block-wrap {
    padding-bottom: 30px;
  }
  .main-modal .product-price-block .price_span {
    font-size: 46px;
  }
}
@media (max-width: 992px) {
  .product-details-content {
    padding-top: 30px;
  }
  .product-details-content .modal-price-wrap {
    /* align-items: flex-start !important; */
  }
  .main-modal .product-price-block .price_span {
    font-size: 38px;
    line-height: normal;
  }
  .main-modal .product-price-block .price_span .currency {
    font-size: 18px;
  }
  .productCostomPosition .productCostomPositionInner .customProduct {
    flex-wrap: wrap;
    padding: 32px 0px;
    gap: 3px;
  }
  .productCostomPosition .productCostomPositionInner .customProduct > div {
    margin: 0 !important;
  }
  /* .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker { */
  /* width: 16px;
    height: 16px; */
  /* } */
  /* .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker
    svg {
    width: 12px;
    height: 12px;
  } */
  /* .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-right-content {
    display: none;
  } 
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-left-content {
    width: 100%;
    border-right: none;
  }
  .product-modal.has-related-products .modal-footer-cs {
    width: 100%;
  } */
}
@media (max-width: 768px) {
  /*.product-modal .modal-header-wrap {
    border-bottom: none;
  }
  .modal-content-main-wrap > div {
    overflow-x: hidden !important;
    height: 100% !important;
  } */

  .main-modal.custom-bs-modal .modal-header-cs {
    /* height: 47px; */
  }
  .modal .product-modal.main-modal .modal-content {
    height: 88vh;
  }
  .main-modal.custom-bs-modal .modal-body-cs {
    /* height: calc(88vh - 68px); */
    /* padding-top: 45px; */
    overflow: hidden;
    height: calc(100% - 20px);
  }

  /* .product-modal .modal-footer-cs {
    bottom: unset;
    top: 47px;
    padding-top: 0 !important;
    height: 42px;
    padding-left: 0;
    padding-right: 0;
    border: 0 !important;
  } */
  .modal .modal-body {
    overflow: hidden;
  }
  /*  
  .product-modal .modal-content-main-wrap .modal-left-content {
    padding-bottom: 0;
    position: relative;
  }
  .product-price-block {
    border-bottom: none;
  }
  */
  .custom-bs-modal .modal-content .add-cart-modal-btn {
    margin-bottom: 20px;
  }
  .product-modal .product-price-block ul li:nth-child(3) {
    display: block;
  }
  /* .main-modal .modal-innerBlock.modal-content-main-wrap,
  .modal .modal-body {
    height: auto !important;
  } */

  /* .main-modal .modal-innerBlock #plus {
    height: auto !important;
  } */
  .info-body-title {
    margin-top: 40px;
  }

  .slider-body {
    height: auto;
    /* padding-top: 0px !important; */
    position: relative;
  }
  .slider-body:first-child::after {
    border-bottom: 0;
  }
  .slider-body::after {
    content: "";
    border-bottom: 1px solid #ccc;
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: 40px;
  }

  .product-modal .product-item-block .discounted-price,
  .product-modal .product-price-block .priceBlock .price-option {
    font-size: 18px !important;
  }
  .product-item-block-wrap {
    padding-bottom: 20px;
  }

  /*.tag-price-modal-block {
    justify-content: end;
    align-items: end;
    border-bottom: none;
    position: absolute;
    top: -55px;
    right: 0px;
    background: transparent;
  }
   .modal-right-content {
    display: none;
  } */
  .modal-left-content > .row {
    position: relative;
  }
  .product-modal .complete-modal-body section {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    border-bottom: 1px solid #d7d7d7;
    &:last-child {
      border-bottom: none;
    }
    div {
      overflow-y: visible !important;
      height: auto !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .row .col-12 .product-details-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      li {
        font-weight: 400;
        overflow-wrap: break-word;
        font-family: Rubik;
        line-height: 1.5rem;
        font-size: 16px;
        font-weight: normal;
        color: rgb(13, 0, 64);
      }
      p {
        font-size: 16px;
        font-weight: 400;
        overflow-wrap: break-word;
        font-family: Rubik;
        line-height: 1.5rem;
        color: rgb(13, 0, 64);
      }
      h6.mb-3 {
        line-height: 1.5rem;
        font-size: 18px;
        font-weight: normal;
        color: rgb(13, 0, 64);
        font-weight: 600;
      }
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
    .modal-left-content {
      padding-bottom: 0;
    }
    div.video-responsive.video_iframe {
      & > P {
        margin: 0 0 25px;
      }
      iframe {
        height: 300px !important;
      }
    }
    &#info {
      & > div > div > p {
        margin: 0 0 25px;
      }
    }
    .select-quantity-wrap {
      height: 48px !important;
      margin-top: 28px !important;
      & > div {
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .product-modal .complete-modal-body {
    padding-left: 22px !important;
    padding-right: 15px !important;

    .slick-slider {
      margin-top: 0;
      margin-bottom: 50px;
      img {
        width: 100%;
        max-height: 350px;
        object-fit: contain;
        height: auto;
      }
    }
    #world,
    #attachment {
      padding: 55px 0 !important;
      min-height: 280px;
      display: flex;
      align-items: center;
      justify-content: stretch;
      & > div {
        width: 100%;
      }

      h6 {
        font-size: 30px;
        font-weight: bold;
        overflow-wrap: break-word;
        font-family: Rubik;
        line-height: 32px;
        color: rgb(13, 0, 64);
        margin: 0 0 40px;
      }
      a {
        border: 1px solid rgb(13, 0, 64);
        color: rgb(13, 0, 64);
        padding: 10px 16px;
        border-radius: 12px;
        text-align: center;
        display: block;
        width: 100%;
        font-weight: 500;
        text-decoration: none;
        font-size: 16px;
      }
    }
  }
  .product-modal .modal-header-cs {
    padding: 13px 15px;
  }
  /* .product-modal .modal-header-wrap {
    padding: 0 !important;
  } */
  .product-modal .modal-header-wrap > div > div {
    height: 18px;
    align-items: center;
  }
  .productdetails-footer {
    display: flex;
    align-items: center;
  }
  .main-modal.product-modal
    .modal-content
    .productdetails-footer
    .modal-footer {
    margin: 0 !important;
  }
  .main-modal.product-modal
    .modal-content
    .productdetails-footer
    .modal-footer
    button {
    min-width: fit-content !important;
  }
  .product-modal #plus .slick-slider img {
    height: 300px;
    object-fit: contain;
  }
  .product-modal .modal-left-content {
    /* padding-top: 70px !important; */
  }
  .product-modal #plus {
    padding-top: 0 !important;
  }
  .product-modal .modal-left-content.no-space {
    padding-top: 0px !important;
  }
  .bodyHeight {
    /* padding-right: 0 !important; */
    height: calc(100vh - 189px);
  }
  .productCostomPositionInner {
    margin-top: -70px;
  }
  /* .productCostomPosition .customProduct:nth-child(1),
  .productCostomPosition .customProduct:nth-child(2) {
    top: 25.5875% !important;
  }
  .productCostomPosition .customProduct:nth-child(3),
  .productCostomPosition .customProduct:nth-child(4) {
    top: 53.1284% !important;
  } */
  .mobile-header,
  .sidebarMenuBlockMobile {
    /* box-shadow: unset; */
  }
  .custom-popover .popover-body {
    height: 100% !important;
  }
  /* .popover.bs-popover-auto {
    top: unset !important;
    bottom: -100% !important;
    transform: translate(0, 0) !important;
    transition: 0.3s;
    height: 0;
  }
  .popover.show.bs-popover-auto {
    height: 100%;
    bottom: 0 !important;
  } */
  .mobile-header .mobile-top-bar .cart-icon svg {
    width: 38px;
    height: 40px;
    padding: 8px;
  }
  .mobile-header .mobile-top-bar .cart-icon .notificatopn-badge {
    top: 0;
    right: 0;
  }
  .product-modal.has-related-products .modal-footer-wrap-cs {
    padding: 0;
  }
  .productCostomPosition .productCostomPositionInner .customProduct {
    padding: 10px 0;
  }
  .productCostomPosition .productCostomPositionInner .customProduct > div {
    margin: 0 !important;
  }
}
@media (max-width: 768px) and (orientation: landscape) {
  .product-modal .modal-left-content {
    padding-top: 0px !important;
  }
  .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker {
    width: 20px;
    height: 20px;
    flex-basis: 20px;
  }
  .searchbarOpenWidth
    .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker {
    width: 16px;
    height: 16px;
    flex-basis: 16px;
  }
  .right-sidebar.sidebar-open
    ~ .main-panel
    .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker {
    width: 15px;
    height: 15px;
    flex-basis: 15px;
  }
  .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker
    svg {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 768px) and (orientation: portrait) {
  .custom-popover .cart-card-wrapper {
    height: calc(100% - 303px);
    overflow: auto;
  }
}
@media screen and (min-device-width: 576px) and (max-device-width: 642px) {
  .productCostomPosition .productCostomPositionInner .customProduct {
    padding: 22px 3px;
    gap: 3px;
  }
}
@media (max-width: 720px) {
  .productCostomPosition .productCostomPositionInner .customProduct {
    padding: 2px 0;
  }
}
@media (max-width: 660px) {
  .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker {
    width: 20px;
    height: 20px;
    flex-basis: 20px;
  }
  .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker
    svg {
    width: 16px;
    height: 16px;
  }
}
@media (max-width: 575px) {
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-right-content {
    display: none;
  }
  .productCostomPosition .productCostomPositionInner .customProduct {
    flex-wrap: wrap;
    /* padding: 32px 10px; */
    gap: 4px;
  }
  .product-modal.has-related-products
    .modal-content-main-wrap
    .modal-left-content {
    width: 100%;
    border-right: none;
    padding-bottom: 0;
  }
  .product-modal {
    height: 100vh;
  }
  .product-modal .modal-header-cs {
    height: 47px !important;
    position: initial;
    z-index: 9;
  }
  .product-modal .modal-footer-cs {
    /* bottom: unset;
    padding-top: 0 !important;
    height: 42px;
    padding-left: 0;
    padding-right: 0;
    border: 0 !important;
    position: fixed;
    top: calc(15vh + 47px); */
    position: initial;
    background: transparent;
    height: 45px;
    padding: 0;
  }
  .modal .product-modal.main-modal .modal-content {
    height: 100% !important;
  }
  .product-modal .modal-content .row {
    height: 100%;
  }
  .product-modal .modal-body-cs {
    height: calc(100% - 92px) !important;
    top: 0 !important;
    /* padding-bottom: 50px !important; */
  }
  .product-modal .complete-modal-body {
    height: 100% !important;
  }
  .modal-content-main-wrap > div {
    overflow-x: hidden !important;
    height: 100% !important;
  }
  .modal-right-content {
    display: none;
  }
  .tag-price-modal-block {
    justify-content: end;
    align-items: end;
    border-bottom: none;
    /* position: absolute;
    top: -55px;
    right: 0px; */
    background: transparent;
    margin: 0 !important;
    padding-bottom: 0;
  }
  .main-modal.custom-bs-modal .modal-body-cs {
    height: calc(85vh - 97px);
    top: 45px;
    position: relative;
    padding: 0;
  }
  .product-modal.has-related-products .modal-footer-cs {
    width: 100%;
    /* bottom: unset !important; */
    /* top: calc(15vh + 46px) !important;
    position: fixed; */
    padding: 0;
  }
  .popover.bs-popover-auto {
    top: unset !important;
    bottom: -100% !important;
    transform: translate(0, 0) !important;
    transition: 0.3s;
    height: 0;
  }
  .popover.show.bs-popover-auto {
    height: 100%;
    bottom: 0 !important;
    z-index: 999 !important;
  }
  .product-modal .modal-header-wrap {
    border-bottom: none;
  }
  .product-modal .modal-header-wrap {
    padding: 0 !important;
  }
  .product-modal .complete-modal-body section .select-quantity-wrap {
    height: 35px !important;
  }
  .tag-block {
    gap: 6px;
  }
  .modal .modal-body .complete-modal-body .tag-block {
    margin-bottom: 20px;
  }
  .product-modal .complete-modal-body #world,
  .product-modal .complete-modal-body #attachment {
    padding: 50px 0 !important;
    min-height: auto;
  }
  .modal_share_custom .modal-header {
    padding: 20px 32px 24px !important;
  }
  .modal_share_custom .modal-body {
    padding: 0 32px 32px;
  }
  .custom-share-container {
    flex-wrap: wrap;
    margin: 0 !important;
    gap: 5px;
  }
  .VarietiesList .select-variety-mobile {
    width: 100%;
    border: 2px solid #dadbe1;
    border-radius: 8px;
  }
  .product-modal
    .complete-modal-body
    section
    .VarietiesList
    .select-variety-mobile
    .css-1dimb5e-singleValue {
    padding: 3px 15px !important;
  }
  .VarietiesList .select-variety-mobile div {
    min-height: unset;
    padding: 0;
    margin: 0;
    border: none;
    color: #dadbe1;
    font-size: 14px;
  }
  .VarietiesList .select-variety-mobile svg {
    right: 5px;
    top: 4px;
  }
  /* .product-modal .complete-modal-body section .VarietiesList .select-variety-mobile  .css-1jw2kmg-MenuList div, */
  .product-modal
    .complete-modal-body
    section
    .VarietiesList
    .select-variety-mobile
    .css-1nmdiq5-menu
    > div
    div {
    padding: 0 15px !important;
  }
  .VarietiesList .select-variety-mobile div:focus-visible,
  .VarietiesList .select-variety-mobile span:focus-visible,
  .VarietiesList .select-variety-mobile input:focus-visible {
    outline: none !important;
  }
  .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker {
    width: 25px;
    height: 25px;
    flex-basis: 25px;
  }
  .productCostomPosition
    .productCostomPositionInner
    .customProduct
    .custom-marker
    svg {
    width: 18px;
    height: 18px;
  }
  .bordo {
    margin-top: -20px;
  }
  .bodyHeight > div:first-of-type .bordo {
    margin-top: 0 !important;
  }
  .bordo img {
    max-height: calc(var(--vh, 1vh) * 75) !important;
  }
  .categories-static.mobile-view .tag-block {
    gap: 16px;
  }
  .categories-static.mobile-view .tag-block .tag-block-item img {
    width: 45px;
    height: 45px;
  }
  .interactive-title span svg {
    width: 24px;
    height: 24px;
  }
  .interactive-title h6 {
    font-size: 28px !important;
    font-family: 'Rubik';
  }
  .interactive-slider .slick-slider {
    margin-bottom: 10px !important;
  }
  .priceinner {
    position: relative;
  }
  .barrato.offerPrice {
    text-align: right;
    margin-top: -12px;
    /* position: absolute; */
    top: 0;
    right: 0;
  }
  .priceKg {
    order: 1;
    position: relative;
    top: -12px;
  }
  .modal.fade
    .modal-dialog.custom-bs-modal
    .modal-content
    .product-details-content
    .add-cart-modal-btn {
    height: 48px !important;
  }
  .product-details-content .VarietiesList {
    margin-bottom: 20px;
  }
}
