.react-strap-doc table,
.form-control-plaintext {
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}
.react-strap-doc .badges-heading-block h1,
h2,
h3,
h4,
h5,
h6 {
  /* color: ${(props) => props.layoutTheme.headingColor} !important; */
  color: #000;
}
.react-strap-doc .form-control-plaintext {
  /* color: ${(props) => props.layoutTheme.headingColor} !important; */
  color: #000;
}
.react-strap-doc legend,
.doc-title {
  /* color: ${(props) => props.layoutTheme.headingColor};     */
  color: #000;
}

.react-strap-doc .doc-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.react-strap-doc .doc-description {
  font-size: 14px;
  font-weight: 500;
  /* color: ${(props) => props.layoutTheme.textColor};     */
  color: #000;
}

.theme-color {
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.intro-detail {
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.sub-heading {
  /* color: ${(props) => props.layoutTheme.headingColor}; */
  color: #000;
}

.mini-text {
  font-size: 12px !important;
  font-weight: 400;
  margin-top: 10px;
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.global-hash-title {
  /* color: ${(props) => props.layoutTheme.headingColor}; */
  color: #000;
}

.checkbox-text label {
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.custom-react-table-theme-class,
.calender-back-class {
  /* background-color: ${(props) => props.layoutTheme.cardBackground}; */
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}
.custom-react-table-theme-class,
.calender-back-class .pagination-bottom button {
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.Page-title .title {
  /* color: ${(props) => */
  /* (props.sidebarTheme.themeName === "themedefault" || */
  /* props.sidebarTheme.themeName === "theme2" || */
  /* props.sidebarTheme.themeName === "theme4") && */
  /* props.layoutTheme.themeName === "theme6" && */
  /* props.layoutTheme.headingColor} !important; */
  color: #000;
}

.Profile-component .profile-header-panel {
  /* color: ${(props) => props.layoutTheme.headingColor}; */
  color: #000;
}
.Profile-component .profile-right-shade {
  /* background-color: ${(props) => props.layoutTheme.cardBackground}; */
  background-color: #fff;
}
.Profile-component .profile-right-shade .feed-card,
.work-body {
  background-color: white;
}

.Profile-component {
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.map-block {
  height: calc(100vh - 300px);
  width: 100%;
}

.map-marker-icon {
  font-size: 40px;
  position: absolute;
  color: #161b1d;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.border-top-radius {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.border-bottom-radius {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.roe-box-shadow {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

.roe-card-style {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  /* background-color: ${(props) => props.layoutTheme.cardBackground}; */
  background-color: #fff;
  border-radius: 6px;
}
.roe-card-style label {
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.roe-card-style .roe-card-header {
  padding: 15px 24px;
  font-weight: 600;
  font-size: 18px;
  /* color: ${(props) => props.layoutTheme.headingColor}; */
  color: #000;
}
.roe-card-style .roe-card-header .hash {
  /* color: ${(props) => props.sidebarTheme.activeColor}; */
  color: #000;
}

.roe-card-style .roe-card-body {
  padding: 0 24px 24px;
}
.roe-card-style .roe-card-body .prism-code {
  margin-bottom: 0px;
}
.roe-card-style .roe-card-body .roe-card-description {
  font-size: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  /* color: ${(props) => props.layoutTheme.textColor}; */
  color: #000;
}

.react-strap-doc .custom-breadcumb .breadcrumb {
  padding-left: 0px;
}

.custom-breadcumb .breadcrumb {
  font-weight: 600;
  background: transparent;
  margin-bottom: 0;
  font-size: 14px;
  text-transform: capitalize;
}

.custom-breadcumb .breadcrumb-item + .breadcrumb-item::before {
  /* color: ${(props) => */
  /* (props.sidebarTheme.themeName === "themedefault" || */
  /* props.sidebarTheme.themeName === "theme2" || */
  /* props.sidebarTheme.themeName === "theme7") && */
  /* props.layoutTheme.themeName === "theme6" */
  /* ? props.layoutTheme.headingColor */
  /* : props.sidebarTheme.activeColor} !important; */
  color: #000;
}

.custom-breadcumb .breadcumb-color {
  /* color: ${(props) => */
  /* (props.sidebarTheme.themeName === "themedefault" || */
  /* props.sidebarTheme.themeName === "theme2" || */
  /* props.sidebarTheme.themeName === "theme7") && */
  /* props.layoutTheme.themeName === "theme6" */
  /* ? props.layoutTheme.headingColor */
  /* : props.sidebarTheme.activeColor} !important; */
  color: #000;
}

.theme-choose-side-block {
  height: 180px;
  width: 80px;
  margin: 10px;
}

.theme-choose-header-block {
  height: 30px;
  margin: 10px;
}

.theme-choose-layout-block {
  height: 100px;
  margin: 10px;
}

.theme-choose-footer-block {
  height: 30px;
  margin: 10px;
}

@media (max-width: 991px) {
  .main-panel {
    /* width: 100% !important; */
  }
  .route-height {
    margin: 0;
  }
}
@media (max-width: 767.98px) {
}
@media (max-width: 575px) {
  .main-panel {
    width: 100% !important;
  }
}
