.close-drawer-icon {
  display: none;
  position: absolute;
  right: 15px;
  top: 18px;
  font-size: 20px;
  z-index: 5;
  cursor: pointer;
  color: #fff;
  /* color: ${(props) => */
  /* props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "white" */
  /* : props.sidebarTheme.textColor} !important; */
}

.sidebar-scrollbar-style {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.2);
  height: 206px;
  transform: translateY(0px);
  /* background-color: ${(props) => */
  /* props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "white" */
  /* : props.sidebarTheme.textColor} !important; */
  opacity: 0.4;
}

.sidelist-header-name {
  text-transform: uppercase !important;
  font-weight: 700;
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 5px 10px 0;
  /* color: ${(props) => */
  /* props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "white" */
  /* : props.sidebarTheme.textColor} !important; */
  opacity: 0.4;
  font-size: 13px;
  white-space: nowrap;
}

.second-child-list-icon {
  line-height: 25px !important;
}

.sideBack {
  background-color: #fff !important;
  overflow: hidden;
  margin-bottom: above;
  margin-top: above;
  /* background: ${palette("primary", 1)}; */
  /* overflow: hidden; */
  /* margin-bottom: ${(props) => */
  /* props.themeSetting.footerAlignValue === "above" ? "50px" : "0px"}; */
  /* margin-top: ${(props) => */
  /* props.themeSetting.toolbarAlignValue === "above" ? "65px" : "0px"} */
}

.sidebar-wrapper .nav-link {
  /* color: ${(props) => */
  /* props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "white" */
  /* : props.sidebarTheme.textColor} !important; */
}
.sidebar-wrapper .arrow-sidebar {
  /* color: ${(props) => */
  /* props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "white" */
  /* : props.sidebarTheme.textColor} !important; */
}
.sidebar-wrapper .main-list {
  /* line-height: 30px;
        font-size: 13px;
        font-weight: 600;
        white-space: nowrap;
        position: relative;
        text-transform: capitalize;
        color: #fff;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        display: block;
        height: auto;
        opacity: 1;
        transition: all .15s linear; */
}
.sidebar-wrapper .child-list {
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  position: relative;
  color: #fff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  display: block;
  height: auto;
  opacity: 1;
  transition: all 0.15s linear;
}
.sidebar-wrapper .active-sidebar-type-class {
  /* border-left: 3px solid ${(props) => */
  /* props.themeSetting.sidebarTransParentValue === "on" */
  /* ? props.themeSetting.sidebarTransParentActiveColor */
  /* : props.sidebarTheme.activeTextColor} !important; */
}
.sidebar-wrapper .active-sidebar-type-class .child-list {
  margin: 5px 7px 0 !important;
}
.sidebar-wrapper .active {
  /* style1 */
  /* background-color: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style1" || */
  /* props.themeSetting.activeLinkStyle === "style5" */
  /* ? props.themeSetting.sidebarTransParentValue === "on" */
  /* ? props.themeSetting.sidebarTransParentActiveBack */
  /* : props.sidebarTheme.activeColor */
  /* : ""}; */
  /* background-color: var(--secondary-color); */
  font-weight: 600;

  /* style5 */
  /* border-bottom-right-radius: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style5" */
  /* ? "30px !important" */
  /* : "4px"}; */
  /* border-top-right-radius: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style5" */
  /* ? "30px !important" */
  /* : "4px"}; */
  /* border-right: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style5" */
  /* ? props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "6px solid white !important" */
  /* : "6px solid " + props.sidebarTheme.textColor + "!important" */
  /* : ""}; */

  /* style2 */
  /* border: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style2" */
  /* ? props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "2px solid " + */
  /* props.themeSetting.sidebarTransParentActiveBack */
  /* : "2px solid " + props.sidebarTheme.activeColor */
  /* : ""}; */
  /* margin:  ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style2" */
  /* ? "5px 8px 0 !important" */
  /* : ""}; */

  /* style3 */
  /* border-left: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style3" */
  /* ? props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "4px solid " + */
  /* props.themeSetting.sidebarTransParentActiveBack */
  /* : "4px solid " + props.sidebarTheme.activeColor */
  /* : ""}; */
  /* border-radius: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style3" */
  /* ? "0 !important" */
  /* : ""}; */

  /* style4 */
  /* color: ${(props) => */
  /* props.themeSetting.activeLinkStyle === "style4" */
  /* ? props.themeSetting.sidebarTransParentValue === "on" */
  /* ? props.themeSetting.sidebarTransParentActiveColor + */
  /* "!important" */
  /* : props.sidebarTheme.activeTextColor + "!important" */
  /* : ""}; */
}

.sidebar-header {
  cursor: pointer;
  position: relative;
  z-index: 4;
  padding: 8px 0.7rem;
  background-color: #e48a00;
  /* background-color: ${(props) => */
  /* props.themeSetting.sidebarTransParentValue !== "on" && */
  /* props.sidebarTheme.header && */
  /* props.sidebarTheme.header}; */
}
.sidebar-header .simple-text {
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1rem;
  color: rgb(255, 255, 255);
  font-weight: 400;
  line-height: 30px;
  text-decoration: none;
  overflow: hidden;
}

.sidebar-header .logo-mini {
  opacity: 1;
  float: left;
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  padding: 6px 0px 8px;
  background-color: #cc0 !important;
}
.sidebar-header .logo-mini img {
  width: 34px;
  height: 34px;
}
.sidebar-header .logo-text {
  display: block;
  opacity: 1;
  transform: translateZ(0px);
  padding: 11px 0px 8px;
  color: #fff;
  /* color: ${(props) => */
  /* props.themeSetting.sidebarTransParentValue === "on" */
  /* ? "white" */
  /* : props.sidebarTheme.textColor} !important; */
  font-weight: 600;
}

.sidebar {
  overflow: visible !important;
}

@media (max-width: 991px) {
  .sidebar {
    width: 260px;
  }
  .close-drawer-icon {
    /* display: block; */
  }
}
