.headerBack {
    /* background: ${props => props.topbarTheme.backgroundColor}; */
    /* margin-left: -${props => (props.themeSetting.toolbarAlignValue === 'above' && !props.mini) ?  */
                    /* props.drawerWidth :   */
                    /* (props.themeSetting.toolbarAlignValue === 'above' && props.mini) ?  */
                    /* props.miniDrawerWidth === props.drawerWidth ? '80px': props.miniDrawerWidth : '0px' */
                /* }; */
    margin-left: "above";
    background-color: #e48a00;
    padding: 5px 10px;
    background: #3ccf01;
    @media  only screen and (max-width: 767.98px) {
        margin-left : 0px !important;
    }
    
    
}
.headerBack .drawer-handle-arrow {
    @media  only screen and (max-width: 767.98px) {
        display: none;
    }
}
.headerBack .mini-drawer-menu-icon {
    margin: 3px auto;
        justify-content: center;
        cursor: pointer;
        display: none;
        /* color: ${props => props.topbarTheme.textColor}; */
        @media  only screen and (max-width: 767.98px) {
            display: block;
        }
}
.headerBack .mini-drawer-menu-icon i {
    font-size: 20px;
    margin-right: 10px;
    position: relative;
    top: 2px;
}
.headerBack .mini-drawer-menu-icon .app-name {
    font-weight: 600;
}
.headerBack .top-header-icon {
    padding: 8px;
        border-radius: 50%;
        cursor: pointer;
        width: 35px;
        height: 35px;
        /* background: ${props => props.topbarTheme.textColor}; */
        
        background: var(--primary-color);
        /* color: ${props => props.topbarTheme.backgroundColor}; */
        color: #fff;;
        display: flex !important;

        align-items: center;
        justify-content: center;
        @media  only screen and (max-width: 575.98px) {
            width: 25px;
            height: 25px;
            padding: 6px;
            margin-top: 2px;
        }
}
.headerBack .top-header-icon i {
    font-size: 16px;
    @media  only screen and (max-width: 575.98px) {
        font-size: 13px;
    }
}

.headerBack .top-header-profile-class {
    border-radius: 50%; 
    width: 35px;
    height: 35px;
    cursor:pointer;
    background-color: #e48a00;
    @media  only screen and (max-width: 575.98px) {
        width: 32px;
        height: 32px;
        margin-top: 2px;
    }
    
}