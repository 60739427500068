/* .categoryDynamic .product-block-inner p .font-icon svg {
  transform: rotate(-180deg);
} */
.categoryDynamic .custom-search-bar-wrapper {
  padding: 0;
}
.categoryDynamic .custom-search-bar-wrapper span {
  position: absolute;
  right: 0;
  height: 100%;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #365da1;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 50%;
  transform: translate(0, -50%);
}
.categoryDynamic .custom-search-bar-wrapper span svg {
  transform: unset !important;
  top: unset !important;
  right: unset !important;
  position: relative !important;
}
.categoryDynamic .custom-search-bar-wrapper span svg path {
  fill: #fff;
}
.categoryDynamic .product-item-list span {
  font-family: "Inter";
}
.categoryDynamic .product-block-inner .font-icon {
  top: 10px;
}
.categoryDynamic .product-item-list.active > span {
  color: #365da1;
  font-weight: bold;
}
.categories-dynamic .tag-block .tag-block-item.colorbox {
  width: 45px;
  height: 45px;
  border-radius: 4px;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .categoryDynamic .sidebarMenuBlockMobile {
    left: 0;
  }
  .product-block-inner p,
  .product-block-inner p > span {
    font-family: Inter;
  }
  .product-subcategory-block {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 100%;
    background: #fff;
    z-index: 1;
    transition: 0.3s;
  }
  .product-subcategory-block.active {
    left: 0;
  }
  .tag-block .tag-block-item.colorbox {
    width: 45px;
    height: 45px;
    border-radius: 4px;
    opacity: 1;
  }
}
